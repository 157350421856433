@import "dbd-variables";
@import "mixins";


.row {

    &.nft-row {

        & > [class*="col-"] {



            @media (min-width: 1921px) {

                @include flex(0 0 12.5%);
                max-width: 12.5%;
            }

            @media (max-width: 1920px) {

                @include flex(0 0 16.666667%);
                max-width: 16.666667%;
            }

            @media (min-width: 1441px) and (max-width: 1775px) {
                @include flex(0 0 20%);
                max-width: 20%;
            }

        }
    }
}