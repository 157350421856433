@import "./dbd-variables";
@import "./mixins";




/* IMAGE FULLSCREEN MODAL */
.model-viewer {
  height: 500px;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  padding: 40px 20px;
  background: rgba(0,0,0,0.9);

  img.main {
    width: auto;
    max-width: 100%;
  }

  .model-viewer {
    height: calc(100%);
  }

  &.nft-wrapper {

    .nft-container {
      max-width: 767px;
      border-radius: 10px;
      overflow: hidden;
      height: auto;
    }
  }

  .btn-fullScreen {
    display: none;
  }

}


.btn-closeFullscreen {
  position: fixed;
  font-size: 1.5em;
  top: 40px;
  right: 40px;
  z-index: 100001;
  cursor: pointer;
  color: #fff;
  border: none;
  background-color: transparent;

  span {
    position: relative;

    i {
      @include centerAlign;
    }

  }

}


.btn-fullScreen {
  position: absolute;
  right: 30px;
  top: 20px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  z-index: 999;
  color: #fff;
  border: none;
  border-radius: 50%;
  background-color: transparent;

  span {
    position: relative;

    i {
      @include centerAlign;
    }

  }

}

.btn-action {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  z-index: 10;
  color: #fff;
  border: none;
  border-radius: 50%;
  background-color: rgba(255,255,255,0.2);
  @include transition(all .3s ease);

  @include respond-below(sm) {
    width: 25px;
    height: 25px;
    right: 15px;
    top: 15px;
  }

  &:hover {
    background-color: rgba(255,255,255,0.3);
  }

  span {
    position: relative;

    i {
      @include centerAlign;
      font-size: 10px;

      @include respond-below(sm) {
        font-size: 9px;
      }
    }

  }
  
}



.avatarImg {
  position: relative;
  object-fit: cover;
  width: 50px;
  height: 50px;

  
  @include respond-below(sm) {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 389px) {
    width: 35px;
    height: 35px;
  }
}


.tag {
  align-items: center;
  background-color: $Grey5;
  border-radius: 8px;
  color: $Grey100;
  display: inline-flex;
  font-size: 12px;
  font-weight: 500;
  line-height: 1em;
  padding: 10px 12px;
  justify-content: center;
  white-space: nowrap;
  text-decoration: none !important;

}

.text-help {
  color: #3e00ac;
}


.expiry-icon {

  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: $Grey5;
  position: relative;


  i {
    font-size: 16px;
    line-height: 1;
    // color:#fff;
    @include centerAlign;
  }
}

.expiry-details {
  font-size: 13px;
  line-height: 1.25em;
}


.dp-wrapper {
  overflow: hidden;
  @include boxShadow(0 0 2px 1px #E8E9EB);
}