@import "./dbd-variables";
@import "./mixins";




@mixin centerAlign {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin horizontalAlign($leftPos) {
  position: absolute;
  left: $leftPos;
  -moz-transform: translateX(-$leftPos);
  -webkit-transform: translateX(-$leftPos);
  -ms-transform: translateX(-$leftPos);
  transform: translateX(-$leftPos);
}


.container {
  max-width: 1440px !important;

  @media (max-width: 1450px) {

    max-width: 1320px;
  }

  &.container-extend {

    max-width: 1920px;

    @media (max-width: 1919px) {
      max-width: 1640px;
    }

    @media (max-width: 1450px) and (min-width: 1670px) {
      max-width: 1440px;
    }
  
    @media (max-width: 1669px) and (min-width: 1350px) {
      max-width: 1320px;
    }
  
    @media (max-width: 1349px) and (min-width: 1201px) {
      max-width: 1200px;
    }
  }

  &.container-nft {
    max-width: 1200px;
  }
  
}


// b,
// strong {
//   font-weight: 600;
// }

h1,
h2,
h3,
h4,
h5,
h6 {
  b,
  strong {
    font-weight: 600;
  }
}

.increase-value {
  color: #45bd21;
}

.decrease-value {
  color:#fa672d;
}

/* You can add global styles to this file, and also import other style files */
.avatar {
  width: 45px;
  height: 45px;
  position: relative;
  object-fit: cover;
}

.profileAvatar {
  width: 175px;
  height: 175px;
  position: relative;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
  @include boxShadow(0 0 2px 1px #E8E9EB);
}

.profileAvatar-mobile {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: relative;
  object-fit: cover;
  overflow: hidden;
  @include boxShadow(0 0 2px 1px #E8E9EB);
}

label.custom-file-label.btn {
  height: inherit;
  width: 100%;
  max-width: 210px;
  margin: 0 auto;
  color: black;
  cursor: pointer;

  &:after {
    display: none;
  }
}

//spinner code start
.spin-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
  background-color: rgba(26, 42, 58, 0.7);
  z-index: 99999;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;

  div {
    position: absolute;
    top: 27px;
    width: 14px;
    height: 16px;
    border-radius: 0 10px 10px 0;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:after {
      content: "";
      display: block;
      width: 7px;
      height: 2px;
      background-color: #fff;
      @include verticalAlign(50%);
    }

    &:nth-child(1) {
      left: 4px;
      animation: lds-ellipsis1 0.5s infinite;
    }

    &:nth-child(2) {
      left: 4px;
      animation: lds-ellipsis2 0.5s infinite;
    }

    &:nth-child(3) {
      left: 24px;
      animation: lds-ellipsis2 0.5s infinite;
    }

    &:nth-child(4) {
      left: 43px;
      animation: lds-ellipsis3 0.5s infinite;
    }
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(19px, 0);
  }
}

.cal-loading {
  color: #000;
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  @include flexBox;
  @include flexAlignCenter(center);
  @include flexVerticalAlignContent(center);
  @include flexDirection(column);

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    background-color: #fff;
  }

  .lds-ellipsis {

    div {
      background: $red;
    }
  }

  p {
    font-size: 12px;
    font-weight: 500;
    white-space: normal;
    margin-bottom: 0;
    z-index: 10;
    margin-top: -15px;
  }
}

.container-loading {
  color: #000;
  width: 100%;
  height: 100%;
  z-index: 99999;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 1;
    background-color: #fff;
  }

  .lds-ellipsis {

    div {
      background: $red;
    }

  }
}

.default-container {
  height: 300px;
}

//spinner code end

small.select-help {
  font-size: 13px;
  margin-bottom: 0;
  color: #3f4e5b;
  padding-left: 5px;
}

small.select-help i {
  color: #264ea8;
  font-size: 16px;
  top: 1px;
}

.form-control.flatpickr {
  background-color: #fff !important;
}

.alert-block {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  display: block;
  position: relative;
  margin: 0 auto 10px;
  z-index: 1000003;

  i {
    font-size: 60px;
    line-height: 1.2;
    @include centerAlign;
  }

  &.success {
    color: #04a80e;
  }

  &.info {
    color: #0500bf;
  }

  &.warning {
    color: #f25929;
  }
}

button.close {
  &:focus,
  &:focus-visible {
    outline: 0;
  }
}

// TOASTER NOTIFICATION

.overlay-container {
  z-index: 1000002;
  position: fixed;
  top: 0;
}

body .modal {
  z-index: 100001;
}

body .modal-dialog {
  width: 100% !important;
}

.filter-section {
  border-bottom: 1px solid #E8E9EB;
}

.collapse-label {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 1;
  width: 100%;
  padding: 20px 40px 20px 0;
  position: relative;
  color: #2E2F4A;
  // color: #1a004c;

  &:after {
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-size: 1.25em;
    position: absolute;
    top: 18px;
    right: 5px;
    transition: all ease 0.2s;
  }

  &:not([aria-expanded="false"]) {
    &:after {
      transform: rotate(-180deg);
      transform-origin: 6px 10px;
      transition: all ease 0.2s;
    }
  }
}

.dark-mode .collapse-label {
  color: white;
  border-color: white;
}

.ng-select-custom {
  text-align: left;

  &.ng-select-customVip {
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:first-child {
      background-color: rgb(255, 189, 0);
      color: rgb(26, 0, 76);

      &.ng-option-disabled {
        background-color: rgba(255, 206, 73, 0.4);
        color: #ccc;
      }

      &.ng-option-marked {
        background-color: #1a004c;
        color: rgb(255, 189, 0);
      }
    }
  }

  &.ng-select-customVipDisabled {
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:first-child {
      background-color: rgba(255, 206, 73, 0.4);
    }
  }

  &.ng-select-customNotVip {
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:first-child {
      background-color: #fff;
      // color: rgb(26, 0, 76);
    }
  }
}

.help-wrapper {
  position: fixed;
  top: 150px;
  right: 0;
  @include calc(height, #{"100vh - 300px"});
  overflow: hidden;
  z-index: 1000;

  @include respond-below(md) {

    top: 120px;
    @include calc(height, #{"100vh - 220px"});
   

  }

  .container {
    padding-left: 35px;
    padding-right: 0;
    position: relative;
    height: 100%;

    .btn.btn-help {
      font-size: 13px;
      font-weight: normal;
      text-transform: initial;
      letter-spacing: 0;
      border: 1px solid purple;
      background: #fff;
      border-radius: 4px 3px 0 0;
      padding: 0 20px 0 0;
      position: absolute;
      top: 175px;
      @include transformValue(rotate(-90deg) translateY(-50%));
      transform-origin: left;

      &:focus,
      &:active {
        box-shadow: none;
      }

      i {
        width: 30px;
        height: 30px;
        display: inline-flex;
        margin-right: 10px;
        align-items: center;
        justify-content: center;
        background-color: purple;
        color: #fff;
      }
    }

    .content-wrapper {
      width: 100%;
      max-width: 400px;
      height: 100%;
      background-color: #fff;
      // @include boxShadow(0 0 8px 0 rgba(0, 0, 0, 0.1));
      margin-right: -450px;
      @include transition(margin-right 0.5s);
      color: #000;
      border: 1px solid $Grey10;
      border-right: none;
      border-radius: 10px 0 0 10px;

      @include respond-below(md) {

        @include calc(width, #{"100vw - 60px"});
        max-width: 100%;

      }

      .content-scroll {
        overflow-y: auto;
        max-height: 100%;

        h6 {
          font-size: 14px;
        }

        p,
        ul li,
        ol li {
          line-height: 1.65em;
          font-size: 12px;

          &.note {
            background-color: $Grey5;
            padding: 10px;
            border-radius: 8px;
            font-size: 85%;

            strong {
              font-weight: 600;
            }
          }
        }
      }

      // & > *:last-of-type {
      //   margin-bottom: 0;
      // }

      &.open {
        margin-right: 0;
      }

      @media (max-width: 450px) {
        width: 300px;
      }

      @media (max-width: 350px) {
        width: 225px;
      }
    }
  }
}

.banner-link {
  color: white !important;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

body.create-policies {
  background-color: #f5f5f5;

  h1 {
    font-size: 2.2em;
  }

  h5 {
    font-size: 1.1rem;
  }
}

.container.policy-container {
  max-width: 600px;

  .card {
    border: none;
    border-radius: 10px;
    @include boxShadow(0px 3px 10px rgba(0, 0, 0, 0.1));

    .card-body {
      padding: 30px;
    }
  }

  .btn {
    &.btn-discount,
    &.btn-next-step {
      padding: 15px;
      border-radius: 40px;
      text-transform: capitalize !important;
    }
  }
}

.row {
  &.currency-container {
    margin-right: -5px;
    margin-left: -5px;

    > .col,
    > [class*="col-"] {
      padding-right: 5px;
      padding-left: 5px;
    }

    .token-wrapper {
      &:nth-last-child(-n + 2) {
        button {
          margin-bottom: 0;
        }
      }
    }
  }
}

.home.progress {
  background-color: transparent;
  line-height: 1;
}

.doughnut-text {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  font-size: 1.5rem;
  padding-top: 0.6rem;
}

.slick-slider {
  width: 88%;
  margin: auto;
  background: rgb(14, 13, 13);
}

body .slick-prev,
body .slick-next {
  height: 45px;
  width: 40px;
  background: grey !important;
  z-index: 100;
}

.bold-600 {
  font-weight: 600;
}

.mat-tab-body {
  padding: 0.8rem 0.8rem 1.2rem 0.8rem;
  background-color: #1a004c;
  color: white;
}

.slick-slider {
  background-color: white;
  color: #1a004c;
}

.mat-tab-label-active {
  background-color: #1a004c !important;
  color: #fff !important;
}

.mat-tab-label {
  border-radius: 20px 20px 0 0;
  background-color: #f1f1f1;
  color: #1a004c;
  font-size: 1.25rem;
  font-weight: 600;
  font-family: "Montserrat";
}

.btn-pink {
  background-color: #e01866;
  color: white;
  padding: 0.75rem 3rem;

  &:hover {
    opacity: 0.9;
    color: white;
  }
}

.doughnut-chart-wrapper {
  display: inline-block;
  position: relative;
  width: 70%;
}

.container-token {
  background-color: #efefef;
  border-radius: 20px;
  padding: 3rem 3rem 2.5rem 3rem;

  @media (max-width: 720px) {
    padding: 1.5rem 2rem;
  }
}

.btn-white {
  background-color: white;
  color: #1a004c;
  font-size: 0.75rem;
  font-weight: 900;
  font-family: "Montserrat";

  &:hover {
    opacity: 0.75;
  }
}

.video-frame {
  height: 400px;

  @media (max-width: 720px) {
    height: 280px;
  }

  @media (max-width: 280px) {
    height: 200px;
  }
}

.polygon-logo {
  width: 30%;

  @media (max-width: 720px) {
    width: 50%;
  }
}

.algorand-logo {
  width: 25%;

  @media (max-width: 720px) {
    width: 43%;
  }
}

.card-share {
  width: 40%;

  @media (max-width: 720px) {
    width: 95%;
    padding: 0.5rem 0.8rem 0 0.8rem;
  }
}

.dashboard-light-font {
  font-weight: 100;
  font-family: "Montserrat";
}

.qrCodeText {
  font-size: 20px;
  font-weight: 600;
}

.max-height-560 {
  max-height: 560px;
}

.sticky-header {
  position: sticky;
  top: -1%;
}

.sentry-error-embed-wrapper {
  z-index: 10001 !important;
}



.row {

  &.slim-gutter {

    margin-left: -7px;
    margin-right: -7px;

   & > .col,
   & > [class*="col-"] {
      padding-right: 7px;
      padding-left: 7px;
    }


  }
}



.dark-mode a:not(.btn) {
  color: white;

  &:hover {
    opacity: 0.5;
  }
}

















.bg-white {
  background-color: #fff !important;
}

.bg-grey {
  background-color: #f0f0f0 !important;
}

.bg-light {
  background-color: $Grey5 !important;
}

.toast-top-center {
  top: 15px !important;
}

.font-weight-extrabold {
  font-weight: 700;
}

.underline {
  &:hover {
    text-decoration: underline;
  }
}

.not-found {
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 12rem;

  p {
    margin-top: 2rem;
    font-weight: 600;
    font-size: 1rem;
  }

  .btn {
    width: 350px;
    height: 40px;
    margin: 2rem;
    box-shadow: 0px 12px 18px -6px rgb(0 0 0 / 30%);

    @media (max-width: 425px) {
      width: auto;
    }
  }

  .btn-back {
    background-color: $red;
    border: none;

    &:hover {
      background-color: rgba($red, 0.5);
    }

    &:active {
      background-color: rgba($red, 0.5);
    }
  }

  .btn-return {
    background-color: $royalBlue;
    border: none;

    &:hover {
      background-color: rgba($royalBlue, 0.5);
    }
  }
}

.lpSale-outer-container {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;

  @media (max-width: 1263px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (max-width: 878px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

// lpSale Card
.lpSale-card {
  border-radius: 10px;
  border: 1px solid $Grey20;
  background-color: #fff;
  height: 100%;

  .image-container {
    height: 300px;

    cursor: pointer;

    img {
      width: 100%;
      //height: 100%;
      max-height: 300px;
      object-fit: cover;
      border-radius: 0 0 10px 10px;
    }
  }

  .details-container {
    padding: 5px 15px 5px;

    .float-right {
      display: flex;
      justify-content: flex-end;
    }

    .title-row {
      display: flex;
      justify-content: center;

      .title-col {
        display: flex;
        align-items: center;
      }

      h3 {
        font-size: 15px;
        margin-bottom: 0;

        a {
          img {
            margin-top: -3px;
          }
        }
      }
    }

    .content-row {
      h3 {
        font-size: 15px;
        margin-bottom: 0;

        a {
          img {
            margin-top: -3px;
          }
        }
      }
    }

    p {
      font-size: 14px;
      margin-bottom: 0;
      padding: 5px 0;
    }

    .price {
      font-size: 18px;
    }

    a {
      font-size: 12px;
    }

    .amount-row {
      margin: 10px 0 0;
      padding: 10px 15px 15px;
      border-radius: 10px;
      background-color: $Grey20;

      label {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.035em;
        line-height: 1.25em;
        margin-bottom: 5px;
      }

      h4 {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
      }
    }

    .btn {
      margin-top: 5px;
    }
  }

  .buy-container {
    display: flex;
    justify-content: center;

    .btn-buy {
      width: 20rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
  }
}

.invest-input-container {
  position: relative;
  border-radius: 15px;
  background-color: $Grey40;

  .input-container {
    @media (max-width: 1080px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1080px) {
      flex: 0 0 100%;
      max-width: 100%;

      button {
        width: 100%;
      }
    }
  }

  button {
    height: 35px;
  }

  .btn-receive-rewards {
    width: auto !important;
    height: auto !important;
    font-size: 0.6rem;
    padding: 8px 10px !important;
    white-space: pre-wrap;
  }
}

.underwrite-details-container {
  background-color: #1a004c;
  color: white;
  border-radius: 10px;
  padding: 8px 10px;
}

.text-dropdown {
  position: relative;
  display: inline-block;
}

@media (max-width: 991px) {
  .text-dropdown-content {
    min-width: 150px !important;
    margin-top: 28px;
  }

  .text-dropdown {
    margin: 10px 0px !important;
    display: flex !important;
    justify-content: center;
  }

  .text-dropdown-content {
    a {
      font-size: smaller;
    }
  }

}

@media (min-width: 991px) {
  .text-dropdown:hover .text-dropdown-content {
    display: block;
  }
}

.text-dropdown:hover .text-dropdown-content {
  display: block;
}

.text-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.text-dropdown {
  .fa-chevron-down {
    font-size: 14px;
    padding-left: 2px;
  }
}

.label-separator {
  display: block;
  width: 100%;
  position: relative;
  margin-bottom: 0.75rem;

  &:before {
    content: "";
    width: 100%;
    height: 0.5px;
    background-color: $Grey10;
    @include centerAlign;
    z-index: 1;
  }

  span {
    font-size: 12px;
    font-weight: 600;
    color: $Grey80;
    display: inline-block;
    text-align: center;
    position: inherit;
    width: auto;
    margin: 0 auto;
    padding: 10px 20px;
    background-color: #fff;
    z-index: 999;
  }
}


.protectionType {
  position: relative;
  cursor: pointer;

  i {
    font-size: 18px;
    color: #d8d8d8;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  img {
    width: 100%;
    max-width: 85px;
    display: block;
    margin: 20px auto 10px;
  }

  p {
    color: black;
    font-weight: 700;
  }

  &.selected {
    border-color: #3e00ac !important;

    i,
    p {
      color: #3e00ac;
    }

    img {
      filter: invert(14%) sepia(82%) saturate(5576%) hue-rotate(263deg)
        brightness(64%) contrast(125%);
    }
  }

  &.disabled {
    img {
      filter: invert(98%) sepia(0%) saturate(30%) hue-rotate(198deg)
        brightness(99%) contrast(75%);
    }
    p {
      color: #d8d8d8;
    }
  }
}

.summary-row {
  border-top: 3px solid #3e00ac;
}

.lightPurpleBorder {
  border-color: #5549dd !important;
}

.purpleBorder {
  border-color: #3e00ac !important;
}

.lightPurpleText {
  color: #5549dd !important;
}

.lightPurpleBackground {
  background: #5549dd !important;
}



// .badge-danger {
//   background-color: #ffeee8 !important;
//   color: #f15a29 !important;
// }

.status-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: inherit;
  position: relative;

  i {
    @include centerAlign;
  }
}

.assetFilterButtons {
  background-color: #f4f3ff;
  color: #2b007e;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  &.selected {
    background-color: #2b007e;
    color: white;
  }
}
.required-span {
  color: red;
}

.rounded-xl {
  border-radius: 10px;
}

// input type switch

.switch-field {
  display: flex;
  overflow: hidden;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: white;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 13px 27px;
  margin-right: -1px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.1s ease-in-out;
  margin-bottom: 0px;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #3e00ac;
  color: white;
  box-shadow: none;
}
.switch-field input:checked:disabled + label {
  background-color: #8f73bf;
}
.switch-field input:disabled + label {
  background-color: #c8cbce;
}

.switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
  border-radius: 0 4px 4px 0;
}


.faucet-bar {
  background-color: $hue-dark-hov;
  padding: 7px 15px;
  
  p {

    font-size: 11px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    line-height: 1.5em;

    @include respond-below(sm) {
      font-size: 10px;
      line-height: 1.75em;
    }

    a {
      font-weight: 700;
      color: #fff;
      text-decoration: none;
    }
  }
}


