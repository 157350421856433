@import "./dbd-variables";
@import "./mixins";

header {
  position: sticky;
  top: 0;
  z-index: 1001;
  @include boxShadow(0 0 8px 2px rgba(0,0,0,0.1));

  #main-container {
    background-color: #fff;
    min-height: 70px;

    @include respond-below(xl) {
      min-height: 60px;
    }

    .nav-link-mob {
      font-size: 12px;
      letter-spacing: 0.025em;
      color: $Grey100;
      text-decoration: none;
      // padding: 10px 20px;
      // background: $red;
      border-radius: 30px; 
    }
  
    /* NAV BAR */
    &.navbar {
      font-size: 13px;
      font-weight: 600;
  
      .navbar-brand {
        margin: 0 10px 0 0;
        padding: 0;
        @include flexInlineBox;
        @include flexAlignCenter(center);
      
        img {
          width: 170px;

          @include respond-below(xl) {
            width: 135px;
          }
        }

        .testnet {
          font-size: 8px;
          font-weight: 700;
          line-height: 1;
          text-transform: uppercase;
          background-color: $hue-warning;
          color: $hue-primary;
          border-radius: 4px;
          display: inline-block;
          padding: 3px 5px;
          align-self: center;

        }
      
        &:hover {
          opacity: 1 !important;
        }
      }
  
      .navbar-nav {
  
        a, div {
          text-decoration: none;
          color: $Grey100;
    
          &:not([href]):not([class]) {
            color: inherit;
          }

          &.nav-link {
            padding-left: 15px;
            padding-right: 15px;
            position: relative;

            @include respond-below(xl) {
              padding-left: 0;
              padding-right: 0;
            }

            @include respond-below(sm) {
              font-size: 14px;
              padding-top: 10px;
              padding-bottom: 10px;
            }

            &[target="_blank"] {

              padding-right: 20px;

              &:before {
                  // content: "\f061";
                  font-family: "Font Awesome 5 Free";
                  font-weight: 900;
                  height: 7;
                  width: 7;
                  position: absolute;
                  right: 0;
                  @include rotate(-45deg);
              }

          }

          &:after {
            content: '';
            display: block;
            width: 30px;
            height: 2px;
            background-color: $red;
            border-radius: 10px;
            @include horizontalAlign(50%);
            bottom: 0;
            opacity: 0;
            transition: all 0.2s;

            @include respond-below(xl) {
              transform: none;
              height: 100%;
              width: 2px;
              left: -30px;
              border-radius: 0;
            }
          }

            &:hover {
              color: initial;
              transition: all 0.2s;

              &:after {
                opacity: 1;
                transition: all 0.2s;
              }
            }
          }
        }
      
        .nav-item {

          align-self: center;

          @include respond-below(xl) {
            width: 100%;
            margin-bottom: 10px;

            &.DBD {
              @include flexOrder(-1)
            }
  
            &.Account {
              @include flexOrder(-2)
            }
          }

          .dropdown-menu {

              background-color: #fff;
              border-radius: 0 0 5px 5px;
              padding-top: 10px;
              padding-bottom: 10px;
              border: none;
              @include boxShadow(0 0 10px 2px rgba(0,0,0,0.1));
              margin-top: 16px;
              min-width: 265px;
              overflow: hidden;

              @include respond-below(xl) {
                margin-top: 0;
                background-color: transparent;
                box-shadow: none;
                padding-top: 10px;
                padding-bottom: 10px;
              }
          
            a.dropdown-item {

              font-size: 12px;
              font-weight: 600;
              line-height: 1.5em;
              letter-spacing: 0.025em;
              padding: 10px 20px;


              @include respond-below(xl) {
                padding-left: 10px;
                padding-right: 10px;
                border-top: 1px solid $Grey10;

                &:first-of-type {
                  border-top: none;
                }
              }

              &:hover,
              &:active,
              &.focus {
                background-color: $Grey5;
                transition: all 0.2s;

                span.label {
                  color: #fff;
                  background-color: $red;
                  transition: all 0.2s;
        
                    &:before {
                        color: #fff;
                    }
        
                }
              }

              &:before {
                right: 20px;
              }

              &:after {
                display: none;
              }

              &[target="_blank"] {
  
                &:before {
                    display: none;
                }
  
            }

            span.label {
              display: inline-block;
              min-width: 90px;
              padding: 10px;
              position: relative;
              margin-left: auto;
              background-color: $Grey5;
              border-radius: 4px;
              transition: all 0.2s;
    
                &:before {
                    content: "\f061";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    position: absolute;
                    right: 20px;
                    @include rotate(-45deg);
                    transition: all 0.2s;
                }
    
            }



          }

          button {

            &.wallet-btn {

              font-size: 13px;
              font-weight: 600;
              letter-spacing: 0.025em;
              text-align: left;
              text-transform: capitalize;
              padding: 10px 20px;
              width: 100%;
              position: relative;
              border-radius: 0;

              @include respond-below(xl) {
                width: 100%;
                padding: 10px;
                border-top: 1px solid $Grey10;

                &:first-of-type {
                  border-top: none;
                }
              }

              &:hover,
              &:active,
              &.focus {
                transition: all 0.2s;
                background-color: $Grey5;

                &:after {
                  transition: all 0.2s;
                  color: #fff;
                  background-color: $red;
                }
              }

              img {
                width: 100%;
                max-width: 30px;
              }

              span {
                margin-left: 15px;
                display: inline-block;
              }

              &:after {
                content: "\f061";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                right: 20px;
                @include verticalAlign(50%);
                @include flexBox;
                @include flexVerticalAlignContent(center);
                @include flexAlignCenter(center);
                width: 30px;
                height: 30px;
                border-radius: 4px;
                background-color: $Grey5;
                transition: all 0.2s;
              }

            
            }
          }

          &.logged-in {

            .dropdown-item {

              @include flexBox;
              @include flexAlignCenter(center);
              cursor: pointer;

              i {
                width: 30px;
                height: 30px;
                margin-left: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $Grey5;
                border-radius: 4px;
                @include flexBox;
                @include flexVerticalAlignContent(center);
                @include flexAlignCenter(center);
              }

              &:hover,
              &:active,
              &:focus {

                color: $red;

                i {
                  color: #fff;
                  background-color: $red;
                }

              }
            }
        }

        }

      }
  

    }
  }

  .navbar-toggler {

    .icon-bar {
      background-color: $Grey80;
    }

    &[aria-expanded="true"] {

      .icon-bar {
        background-color: $Grey80;

        &+.icon-bar {
          margin-top: 5px;
        }
      }

    }

  }

  .navbar-toggler {
    border: none;
    background: transparent !important;
    position: absolute;
    right: 10px;
    z-index: 99999;

    &[aria-expanded="true"] {
      position: fixed;
      right: 10px;
    }

    &:hover,
    &:active,
    &:focus {
      background: transparent !important;
    }

    .icon-bar {
      height: 2px;
      width: 25px;
      display: block;
      background-color: $Grey80;
      transition: all 0.2s;
      border-radius: 1px;

      &+.icon-bar {
        margin-top: 3px;
      }
    }

    .top-bar {
      @include transformValue(rotate(45deg));
      transform-origin: 10% 10%;
    }

    .middle-bar {
      opacity: 0;
    }

    .bottom-bar {
      @include transformValue(rotate(-45deg));
      transform-origin: 10% 90%;
    }

    &[aria-expanded="false"] {

      .top-bar {
        transform: rotate(0);
      }

      .middle-bar {
        opacity: 1;
      }

      .bottom-bar {
        @include transformValue(rotate(0));
      }

    }

  }

  .navbar-collapse {

    @include respond-below(xl) {
      // background-color: $darkRoyalBlue;
      padding: 30px;
    }

    @include respond-below(sm) {
      padding: 30px 22px 22px;
    }

    .menu-footer {
      color: #fff;
      line-height: 1.15em;

      h4 {
        font-size: 16px;
        font-weight: 300;
        text-transform: uppercase;
      }

      a {
        font-size: 12px;
        color: #fff;
        text-decoration: none;
      }

      ul.social-media-list {
        padding: 0;
        margin: 0;
        list-style: none;
        @include flexBox;

        li {

          padding-right: 10px;

          &:last-child {
            padding-right: 0;
          }

          img {
            width: 25px;
            height: 25px;
          }

        }
      }
    }

  }

  @include respond-below(xl) {

      .navbar-collapse {
        width: 100%;
        max-width: 350px;
        height: 100vh;
        @include transition(all 0.2s ease);
        display: none;
        position: fixed;
        top: 0;
        right: -100%;

        @include respond-below(xs) {
          @include calc(max-width, #{"100vw - 80px"});
        }

        &:before {
          content: '';
          display: none;
          opacity: 0;
          @include transition(all 0.2s ease);
        }

        &.collapsing {
          // height: auto !important;
          margin-right: -50%;
          display: block;

          &:before {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            right: 0;
            display: block;
            opacity: 1;
            background-color: rgba(0,0,0,0.4);
            z-index: -1;
            @include transition(all 0.2s ease);
          }

        }

        &.show {
          display: block;
          right: 0;

          &:before {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            display: block;
            opacity: 1;
            background-color: rgba(0,0,0,0.8);
            z-index: -1;
            @include transition(all 0.2s ease);

          }

          &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            background-color: #fff;
            z-index: -1;
            @include transition(all 0.2s ease);
          }
        }

      }
  }
  
  }


  .btn {

    &.dropdown-btn {
      font-size: 13px;
      font-weight: 600;
      line-height: 1.35em;
      text-transform: capitalize;
      text-align: left;
      letter-spacing: 0.025em;
      color: #0F0F31;
      position: relative;
      border-radius: 40px;
      padding: 10px 40px;
      padding-left: 45px;
      // border-color: $Grey20;

      @include respond-below(xl) {
        width: 100%;
      }

      @include respond-below(sm) {
        padding: 16px 30px;
        padding-left: 55px;
        font-size: 14px;
      }

      &:hover {
        background-color: $Grey5;
      }

      .icon-wrapper {

        width: 30px;
        @include verticalAlign(50%);
        left: 4px;
        text-align: center;

        i {
          color: #fff;
          font-size: 18px;
          line-height: 1.5em;

          &.mobile {
            color: #fff;
            font-size: 1.2em;
            line-height: 1;
            background: $red;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            @include flexBox;
            @include flexVerticalAlignContent(center);
            @include flexAlignCenter(center);
          }
        }

        img {
          width: 100%;
          max-width: 100%;
          display: block;
          height: auto;
          object-fit: contain;
        }
      }

      &:after {
        content: "\f078";
        font-family: "Font Awesome 5 Free";
        font-size: 80%;
        font-weight: 900;
        color: inherit;
        // @include verticalAlign(50%);
        @include transformValue(translateY(-50%));
        transition: all ease 0.2s;
        position: absolute;
        top: 50%;
        right: 15px;
      }

      &[aria-expanded="true"] {
        &:after {
          @include transformValue(translateY(-50%) rotate(-180deg));
          transform-origin: 5px 8px;
          transition: all ease 0.2s;
        }
      }

      &.wallet {
        background-color: $red;
        border-color: $red;
        color: #fff;

        &:hover {
          background-color: $red;
          border-color: $red;
        }

        // &:not(.connected) {
        //   padding-left: 40px;
        // }

        &.connected {

          color: $Grey100;
          background-color: $Grey5;
          border-color: $Grey5;

          &:hover {
            background-color: $Grey10;
            border-color: $Grey10;
          }

        }
      }

    }

    &.dropdown-btn-normal {
      // border-radius: 0px !important;
      // padding: 10px 40px;
      padding-left: 15px !important;
    }


  }
}



/* PROFILE LINK */
.dropdown-toggle .user-name {
  font-size: 13px;
  font-weight: 600;
  color: #000;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // float: left;
  margin-top: 11px;
}


div.dropdown.nav-link.dropdown-area.show,
.dropdown-area a {
  text-align: center;
}

.link-wallet-text {
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 8px;
  width: 475px;
  word-break: break-word;

  @media (max-width: 475px) {
    width: 100%;
  }
}

.btn-link-wallet {
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 20px;
  padding: 15px;
  width: 475px;
  text-align: center;

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 475px) {
    width: 100%;
  }
}


@media (max-width: 1140px) {
  .nav-link {
    letter-spacing: 0 !important;
  }

  .user-name {
    font-size: 11px !important;
  }
}

@media (max-width: 991.98px) {
  .dropdown-area,
  .dropdown-area a {
    margin-top: 8px;
  }

  .dropdown-toggle .user-name {
    max-width: 250px;
  }
}



.normallogo {
  display: block;
}

.whitelogo {
  display: none;
}

::ng-deep .dark-mode {
  .normallogo {
    display: none;
  }

  .whitelogo {
    display: block;
  }
}

.navbar_externalArrow___VWBd {
  position: absolute;
  opacity: 50%;
  // top: 2px;
  // right: -10px;
}