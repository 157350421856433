@import "dbd-variables";
@import "mixins";




.card {

  border-radius: 8px;
  border-color: $Grey10;

  .card-body {

    &.terms {

      p {

        @include respond-below(md) {
          font-size: 13px;
        }
      }
    }
  }
}