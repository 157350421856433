@import "dbd-variables";
@import "mixins";



h3 {

    @include respond-below(lg) {

        font-size: 1.5rem;
    }

    @include respond-below(sm) {

        font-size: 1.35rem;
    }

    
}

