@import "dbd-variables";
@import "mixins";



    .modal-content {

        border: none;
        border-radius: 8px;

        .modal-header {

            padding: 1.5em;

            .close {
                font-size: 34px;
                line-height: 1;
                font-weight: 300;
                padding: 0;
                margin: 0;
                margin-left: auto;
            }

        }

        h5 {
    
            &.connect-title {
                font-size: 16px;
                // @include flexBox;
                // @include flexAlignCenter(center);
                margin-bottom: 0;

                @include respond-below(sm) {
                    font-size: 14px;
                }
    
                i {
                    font-size: 135%;
                }
            }
          }

        .modal-body {

            padding: 1.5em;

            img {

                &.network-logo {
                    width: 100%;
                    max-width: 135px;
                    max-height: 30px;

                    @include respond-below(sm) {
                        max-width: 110px;
                        max-height: 25px;
                    }
                }
              }

        }

        .modal-footer {

            padding: 1.5em;

        }

        
      }

    .connectModal {

    }

    .dark-mode {

    }
