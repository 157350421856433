// Any custom css found only in Staking application

::selection {
  color: white;
  background: $darkRoyalBlue;
}

body {
  // background-color: $Grey5;
  background-image: url("../../assets/daybyday_bg.png");
}

.text-primary {
  color: #3a3dbb !important;
}

.alert {

  &.alert-primary {
    background-color: #d9dbf1 !important;
  }
}

.landingButtonCard {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  min-width: 265px;
  background-color: #fff;
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  text-align: center;

  .btn-outline-secondary {
    border-color: $Grey20;
    color: $darkRoyalBlue;
  }
}

.Amt-Primary {
    color: $red;
}

.pool-container {

    width: 100%;
    max-width: 960px;
    min-height: 500px;

}

.pool-table {
    width: 100%;
    font-size: 13px;
    border-spacing: 0;

    th {
        padding: 12px 15px;
        border: none;
    }

    td {
        padding: 15px;
        border: none;
    }

    thead {

        th {
            font-weight: normal;
            background-color: $Grey100;
            color: #fff;
            border-bottom: 0;

            &.border-side-left {
                border-radius: 8px 0 0 8px;
            }
    
            &.border-side-right {
                border-radius: 0 8px 8px 0;
            }
        }

        &.spacing {

            display: block;
            height: 10px;

            tr {

                th {
                    padding: 0;
                    background-color: $Grey5;
                }
            }


        }

    }



    tbody tr {
        background-color: $Grey10;
        min-height: 60px;

        &:nth-child(odd) {
            td {
                background-color: #fff;
            }
        }

        td {
            border-top: none !important;
            border-bottom: 10px solid $Grey5 !important;

            &.title {
                font-size: 12px;
                text-align: left;
            }
        }

        &:last-of-type {

            td {
                border-bottom: none;
            }
        }
    }

    .btn-secondary,
    .btn-secondary:not(:disabled):not(.disabled).active {

        &.btn-fill {
            background-color: #fff;
            color: $Grey100;
    
            &:hover,
            &:active,
            &:focus {
                border-color: $red;
                background-color: $red;
                color: #fff;
            }
        }

    }



    .btn {
        width: 105px;
    }
  

  .btn-fill {
    background-color: white !important;
    color: $Grey100 !important;
    border-color: $Grey100;

    &:hover {
      border-color: #ee2d38;
    }
  }

  .btn {
    width: 105px;
  }
}

.icon-white {
  color: #fff;
}

.legacyLink {
  color: $Grey100;
  font-size: 12px;
}

.sub-heading {
  &.large {
    font-size: 16px;
  }
}

.btn-thick {
  height: 40px;
}

.staking-actions-container {
  .pill {
    width: 100%;
    height: 60px;
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.pill-balance {
      background-color: $Grey10;
    }

    &.pill-amount {
      border: 1px solid $Grey20;

    }

    @include respond-below(ss) {
      .wallet-address {
        display: none;
      }
    }

    .wallet-bubble {
      height: 40px;
      width: 40px;
      background-color: $picotee-blue;
      border-radius: 25px;
      margin-left: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: white;
        font-size: 20px;
      }
    }

    .hang-right {
      margin-right: 28px;
    }
  }
}

.container {

  &.stake-container {

    max-width: 960px !important;

    h5.stake-info {
      font-weight: 500 !important;
    }
  
  }
}


.card {

  &#stakeCard {

    background-color: #fff;
    max-width: 480px;
    overflow: hidden;
  }

  label {
    font-weight: 500;
  }

  hr.card-divider {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }
}

.btn {

  &.addToMetamask {
    background-color: white;
  }

  &.btn-nft {
    border: none;
    font-weight: 600;
    color: $Grey100;
    border-radius: 8px;

    span {
      display: block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: $red;
      color: #fff;
      font-size: 18px;
      position: relative;

      svg {
        @include centerAlign;
      }
    }
  }
}



.alert {

  &.alert-warning {
    border-color: #f7d3a5;
  }
}



.input-group.stake-group {

  position: relative;

  .input-group-prepend {
    min-width: 60px;
    position: absolute;
    left: 0;
    height: 100%;
    z-index: 10;

    #dbdLogo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: contain;
    }
  
  }


  .form-control {

    &.stakeInput {
      border-color: $Grey20;
      border-radius: 3rem;
      padding-left: 60px;
      padding-right: 80px;
      text-align: right;
      font-size: 18px;

      // Chrome, Safari, Edge, Opera
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }

      // Firefox
      &[type=number] {
          -moz-appearance: textfield;
      }
    }
  }

  .max-container {
    position: absolute;
    height: 100%;
    text-align: center!important;
    margin: auto;
    display: flex;
    right: 0;
  }

  .btn-max {
    height: 50%;
    margin-top: 13px;
    z-index: 10;

    font-size: 11px;
    font-weight: bold;
    background-color: $Grey10;
    border: none;
    border-radius: 8px;
    color: black;
  }

}


.stakeNFT-wrapper {
  position: relative;
  max-width: 275px;
  cursor: pointer;

  span.edit {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: $Grey10;

    svg {
      font-size: 12px;
      @include centerAlign;
      margin-left: 1px;
    }
  }

}

.stakeNFT-title {
  font-size: 13px;
  font-weight: 600;
}

.nft-container {

  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    transform: none;
  }

}

span.selected {
  font-size: 14px;
  color: #fff;
  border: 1px solid $Grey60;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 20px;
  right: 25px;
  z-index: 10;
  @include flexBox;

  svg {
    display: none;
  }
}

.active-nft {

  span.selected {
    background-color: $red;
    border-color: $red;

    svg {
      display: block;
    }
  }
}

.nft-body {
  max-height: 75vh;
  overflow-y: auto;
}


.table-responsiveness {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.modal-content {
  @include respond-below(sm) {
    width: 97%;
  }

  @include respond-below(xs) {
    h5 { 
      font-size: 13px;
    }

    p {
      font-size: 13px;
    }

    .modal-footer .btn {
      font-size: 8px;
    }
  }
  
}

body .modal-dialog {
  @include respond-below(xs) {
    width: auto !important;
    margin-right: 0.5rem;
  }
}

.dbd-purchase {
  &.extra-margin {
    @include respond-above(xl) {
      margin-right: 90px;
    }
  }

  margin-right: 20px;
}