@import "dbd-variables";
@import "mixins";


  .footer {
    position: relative;
    background: #FCFCFC; 
    margin-top: auto;
    height: auto !important;
    // z-index: 99999;

    a {

        color: $Grey100;
        text-decoration: none;

        &:hover {
            opacity: 1;
            text-decoration: none;
        }

        &:not(.btn):hover {
            color: initial;
        }
    }

    h6 {
        margin-bottom: 1.5em;

        @include respond-below(sm) {
            margin-bottom: 1em;  
        }
        
    }

    .footer-row-1 {

    }

    .footer-row-2 {

        border-top: 1px solid $Grey10;
        
        p {
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 0;
            padding-left: 15px;
            padding-right: 15px;

            @include respond-below(sm) {
                padding-left: 10px;
                padding-right: 10px;

                &:first-of-type {
                    text-align: center;
                    width: 100%;
                    margin-bottom: 10px;

                }
            }


            a {

                color: $Grey100;

                &:hover,
                &:active,
                &:focus {
                    color: $red;
                }
            }

        }
    }


    ul {

        li {
            font-size: 12px;
            font-weight: 500;
            padding-bottom: 10px;

            @include respond-below(sm) {
                font-size: 11px;
                padding-bottom: 5px;
            }

            &:last-of-type {
                padding-bottom: 0;
            }

            a {

                color: $Grey100;

                &:hover,
                &:active,
                &:focus {
                    color: $red;
                }

                &[target="_blank"] {
                    position: relative;
                    padding-right: 17px;
    
                    &::after {
                        content: "\f061";
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        position: absolute;
                        right: 0;
                        @include rotate(-45deg);
                    }
                }
            }
        }


        &.addToMetamask {

            list-style: none;
            padding: 0;
            margin: 0;
            white-space: normal;
    
            li {
    
                display: inline-block;
                margin-bottom: 7px;
                width: 100%;
                max-width: 250px;
                padding-bottom: 0;

                @include respond-below(lg) {
                    max-width: 100%;
                }
    
                &:last-of-type {
                    margin-bottom: 0;
                }
    
                button {
    
                    font-size: 12px;
                    font-weight: 500;
                    color: $Grey80;
                    text-align: left;
                    text-decoration: none;
                    padding: 8px 10px 7px 40px;
                    border-radius: 40px;
                    // background-color: $Grey5;
                    border: solid 1px #0F0F31;
                    position: relative;
                    display: flex;
                    justify-content: flex-start !important;
                    cursor: pointer;
                    width: -moz-fit-content;
                    width: fit-content;

                    @include respond-below(sm) {
                        font-size: 11px;
                        padding: 10px 40px 10px 45px;
                    }
    
                    &:hover {
                        text-decoration: none;
                        border-color: $Grey40;
                    }
    
                    img {
                        width: 100%;
                        max-width: 20px;
                        height: auto;
                        border-radius: 50%;
                        background-color: #fff;
                        @include verticalAlign(50%);
                        display: block;
                        left: 5px;

                        @include respond-below(sm) {
                            max-width: 30px;
                        }
                    }
    
                    i {
                        @include verticalAlign(50%);
                        right: 20px;
                    }
    
                }
    
            }
        }


        &.social-media-links {

            list-style: none;
            padding: 0;
            margin: 0;
            white-space: normal;
    
            li {
    
                display: inline-block;
                margin-bottom: 7px;
                width: 100%;
                max-width: 250px;
                padding-bottom: 0;
    
                &:last-of-type {
                    margin-right: 0;
                }
    
                a {
                    display: inline; 
                    border-radius: 8px;
                    // background-color: $Grey5;
                    position: relative;
                    font-size: 1.25em;
                    // color: $Grey60;
                    line-height: 1;
                    width: 16px;
                    height: 16px;
                    
                    &[target="_blank"] {
                        padding: 0;

                        &::after {
                            display: none;
                        }
                    }
    
                    svg {
                        @include centerAlign;
                    }
                }
    
    
            }
        }

    }



}





.cookie-banner {

    z-index: 9999;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff;
    @include boxShadow(0 0 8px 2px rgba(0,0,0,0.1));
  
    a {
      cursor: pointer;
      text-decoration: underline;
      color: #000;
      display: inline-block;

      &.close {
        font-weight: 300;
        text-decoration: none;
        color: #000;

        @include respond-below(sm) {
            margin-top: -30px;
        }

      }
    }
  
    p {
      margin-bottom: 0px;

      @include respond-below(sm) {
        font-size: 12px;
    }
    }
  }
  
