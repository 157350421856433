/* Styles go here */
* {
  font-family: "Montserrat", sans-serif;
}

body,
p,
h1,
h2,
h3 {
  color: #2E2F4A;
}

.primary-color {
  color: #ff2e43;
}

.secondary-color {
  color: #1a004c;
}

.bg-primary-color {
  background-color: #ff2e43 !important;
}

.bg-secondary-color {
  background-color: #f1f1f1 !important;
}

.return-rates {
  text-align: center;
}

/* Title Bar */
.titlebar {
  margin-top: 20px;
  margin-bottom: 25px;
}

.titlebar .titlebarContent {
  margin-bottom: 25px;
  padding-bottom: 5px;
}

.titlebar .heading {
  color: #1a004c;
  font-size: 25pt;
  font-weight: 600;
  margin-bottom: 0px;
  text-align: center;
}

#auditButton {
  background-color: rgb(26, 0, 76);
  margin: 0 auto;
  display: block;
  color: white;
  width: 500px;
  cursor: pointer;
  border-radius: 40px;
  margin-top: 60px;
}

.menuLinks p,
.menuLinks button {
  color: rgb(26, 0, 76) !important;
  font-weight: bold;
  margin-left: 62px;
  margin-right: 62px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (max-width: 1950px) {

  .menuLinks p,
  .menuLinks button {
    margin-left: 38px;
    margin-right: 37px;
  }
}

@media (max-width: 1750px) {

  .menuLinks p,
  .menuLinks button {
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media (max-width: 1550px) {

  .menuLinks p,
  .menuLinks button {
    margin-left: 7px;
    margin-right: 8px;
  }
}

@media (max-width: 1240px) {
  .menuLinks p {
    font-size: 14px;
  }

  .dropbtn {
    border-radius: 9px;
    background-color: #F1F1F1;
    color: #1A004C;
    padding: 8px;
    font-size: 10px !important;
    font-weight: bold;
    border: none;
    min-width: 100px;
  }
}

@media (max-width: 1180px) {
  .menuLinks p {
    font-size: 12px;
  }
}

@media (max-width: 1250px) {

  .menuLinks p,
  .menuLinks button {
    margin-left: 9px;
  }
}

@media (max-width: 1685px) {
  .dropdown-content {
    right: 0px;
  }
}

@media (max-width: 1550px) {

  .dropdown-content {
    right: 0px !important;
    left: 9px !important;
  }
}

.menuLinks:hover {
  color: rgba(27, 0, 76, 0.459);
}

.footerContent p,
.footerContent p a {
  color: rgb(26, 0, 76);
  font-size: 16px;
}

#websiteByAsta {
  margin-left: -10px;
  height: 22px;
}

@media (max-width: 1024px) {

  .footerContent p,
  .footerContent p a,
  .footerCopyW {
    font-size: 14px;
  }

  #websiteByAsta {
    margin-left: -8px;
    height: 20px;
  }
}

.auditBtn {
  background-color: rgb(26, 0, 76) !important;
}

.icon {
  margin-top: 35px;
  color: white;
  text-align: center;
}

.stakeModalIcon {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.card-width {
  max-width: 790px;
}

.card-width-extended {
  max-width: 1100px;
}

.withdrawn-card {
  max-width: 1700px;
}

.genSettingsHeading {
  padding-left: 15px;
  padding-top: 15px;
}

.admin-padding {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

#main {
  min-height: 65vh;
}

.minMaxTrans {
  font-size: 14px;
  text-align: center;
}

@media (min-height: 1657px) {
  .footerId {
    /* bottom: 0; */
    position: "absolute";
  }
}

@media (min-height: 2017px) {
  .footerIdAdmin {
    bottom: 0;
    position: "absolute";
  }
}

.btnClass {
  background-color: #e9e9e9;
  color: #1a004c;
}

@media (max-width: 375px) {

  .btnClass,
  .minMaxTrans,
  #auditPara {
    font-size: 14px !important;
  }
}

@media (max-width: 328px) {

  .btnClass,
  .minMaxTrans,
  #auditPara {
    font-size: 12px !important;
  }
}

.lottery {
  text-align: center;
}

#account {
  font-size: medium;
  color: #ff2e43;
}

#card {
  border-radius: 20px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 16%);
  margin-bottom: 10px;
}

#stakeCard {
  border-radius: 20px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 16%);
  margin-bottom: 10px;
  background-color: #f1f1f1;
  border: none !important;
}

#stakeReturnHead {
  color: black;
  font-weight: bold;
  font-size: 18px;
  padding-top: 10px;
}

/* Staking styles */
#tableButton {
  background-color: #ff2e43;
  color: white;
  border-radius: 50px;
  padding-right: 50px;
  padding-left: 50px;
  /* margin-bottom: 10px; */
  width: fit-content;
}

#headerButton {
  background-color: #ff2e43;
  color: white;
  border-radius: 20px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 10px;
  width: 250px;
  cursor: pointer;
}

.react-bs-table {
  overflow-y: scroll !important;
  max-height: 500px !important;
  padding: 1rem !important;
}

.primary-btn {
  background-color: #ff2e43;
  color: white;
  font-size: 20px;
}

.primary-btn:hover {
  color: #1a004c;
}

/* .stakeCardBtn {
  background-color: #ff2e43;
  color: white;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 17px;
  border-radius: 30px;
} */

.loading {
  color: "#1A004C";
  width: 100%;
  height: 100%;
  z-index: 999;
}

.loading p {
  width: 100%;
  height: 100%;
  font-size: large;
  font-weight: 600;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  word-break: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.loading::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(225, 225, 225, 0.8);
  z-index: 2;
}

.approveSpinner {
  margin-left: 25px;
}

#walletBalance {
  color: rgb(26, 0, 76) !important;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  margin: auto;
  display: block;
}

#stakingHistory {
  color: rgb(26, 0, 76) !important;
  font-weight: bold;
}

.generalText {
  color: rgb(26, 0, 76) !important;
}

#inputAddon {
  background-color: white;
}

.durationRadio input[type="radio"] {
  opacity: 0.01;
  z-index: 100;
}

.durationRadio input[type="radio"]:checked+label {
  background: #ff2e43;
}

.durationRadio label {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 7px;
  padding-top: 7px;
  cursor: pointer;
  /* z-index: 90; */
  border-radius: 25px;
  background: #1a004c;
  font-weight: bold;
  color: white;
}

.durationRadio label:hover {
  background: #ff2e43;
}

#approvalBtn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.linkText {
  color: #1a004c !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}

.form-control:disabled {
  background-color: #f1f1f1 !important;
}

@media (max-width: 450px) {
  .durationRadio {
    font-size: 14px;
  }

  .apyElement {
    font-size: 11px;
  }
}

@media (max-width: 400px) {
  .duarationSelection {
    padding: 0;
  }
}

.addToken {
  padding-left: 5px;
}

.networkText {
  color: #1a004c;
  font-size: 15px;
  text-align: center;
}

.SwitchnetworkText {
  color: #1a004c;
  font-size: 12px;
  text-align: center;
}

.apyElement {
  text-align: center;
  color: rgb(26, 0, 76) !important;
  font-weight: bold;
  font-size: 12px;
}

#address {
  background-color: #f1f1f1;
  border-radius: 10px;
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 1367px) {
  #address {
    padding-left: 10px;
    padding-right: 10px;
    width: fit-content;
  }
}

.titlebar .content {
  text-align: center;
  margin-top: 25px;
}

.value {
  color: #ff2e43;
  font-size: 1.7vw;
  font-weight: bold;
}

@media (min-width: 1440px) {
  .value {
    font-size: 25px;
  }

  .valueTitle {
    font-size: 15px !important;
  }

  .networkText {
    font-size: 15px !important;
  }
}

@media (max-width: 1000px) {
  .value {
    font-size: 30px;
  }

  .valueTitle {
    font-size: 12px !important;
  }

  .networkText {
    font-size: 12px !important;
  }
}

.valueTitle {
  color: #1a004c;
  font-size: 15px;
}

/* .stakeInput {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  border-bottom-color: #1a004c;
  border-bottom-width: 3px;
  background-color: #f1f1f1;
  text-align: right;
  font-weight: bold;
  color: #1a004c;
  font-size: 28px;
  padding-left: 25px;
  padding-right: 10px;
  padding-bottom: 0;
  vertical-align: bottom;
}

.stakeInput:focus {
  background: #f1f1f1;
  color: #1a004c;
} */

#inputAddon {
  background-color: #f1f1f1;
  border: 0;
  min-height: 100%;
}

.direct-btn {
  width: 300px;
  height: 100px;
  background-color: #f1f1f1;
  border-radius: 1rem;
  box-shadow: 3px 5px #f8f9fa;
}

@media (max-width: 300px) {
  .direct-btn {
    width: 250px;
  }
}

.direct-btn:hover {
  background-color: #f8f9fa;
}

.stakeCardBody {
  text-align: center;
  margin: auto;
  display: block;
}

#dbdLogo {
  height: 56px;
  text-align: center;
  margin: auto;
  display: block;
}

@media (max-width: 300px) {
  #dbdLogo {
    height: 35px;
  }

  .linkText {
    font-size: small;
  }

  /* .stakeCardBtn {
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 14px;
    border-radius: 30px;
  } */
}

.stakeFooter {
  text-align: center;
  display: block;
}

#auditLink {
  font-weight: bold;
  color: #0500bf;
  text-decoration: underline;
  cursor: pointer;
}

#auditPara {
  font-weight: bold;
}

.useCaseSection {
  background: rgb(26, 0, 76);
  background: linear-gradient(0deg,
      rgba(26, 0, 76, 1) 0%,
      rgba(5, 0, 191, 1) 100%);
}

.iconTitle {
  color: white;
}

#gif {
  width: 80%;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 50px;
}

#gifcontainerTop,
#gifcontainerBottom {
  height: auto;
}

.iconImage {
  height: 70px;
  margin-bottom: 30px;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

/* #PauseStaking {
  color: rgb(26, 0, 76) !important;
  font-weight: bold;
  text-align: center;
  margin: auto;
  display: block;
  padding-top: 10px;
} */

@media (max-width: 400px) {
  .staketables {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  #tableButton {
    padding-right: 25px;
    padding-left: 25px;
  }

  #DBDHeaderLogo {
    height: 60px;
  }
}

.tableHeading {
  font-weight: bold;
}

.staketables {
  background-color: #f1f1f1;
}

.table td,
.table th {
  border: 0;
  border-bottom: 0 !important;
}

tbody {
  border-bottom: 1px solid #dbdbdb;
}

/* .stakeCardBtn:hover {
  color: #1a004c;
} */

table,
.tableTotal {
  color: #1a004c !important;
}

.selDurLabel {
  font-size: 12px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 50px #e2e2e2;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

.adminThresholds {
  padding-right: 0px !important;
}

.stakingHistoryInfo {
  align-items: center;
}

.dropdown {
  position: relative !important;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute !important;
  background-color: #f1f1f1;
  right: 12px;
  top: 72px;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #E9E9E9;
}

.menuLinksdisabled p {
  color: black !important;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.menu-small {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  /* margin-left: 20px !important; */
  padding-top: 0px !important;
}

@media (max-width: 1250px) {

  .menuLinks p,
  .menuLinks button {
    /* margin-left: 9px; */
    font-size: 12px;
  }
}

@media (max-width: 1205px) {

  .menuLinks p,
  .menuLinks button {
    /* margin-left: 9px; */
    font-size: 11px !important;
  }
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:active {
  color: rgba(0, 0, 0, 1);
}