// Colours




$dbd-black: #0C0E3B;
$darkRoyalBlue: #1a004c;
$red: #EE2D38;
$magenta: #e01766;
$orange: #f25929;
$royalBlue: #0500bf;
$yellow: #ffbd00;


// COLOURS

$persian-orange: #ec9057;
$mandarin: #e77a4b;
$flame: #eb5f33;

$picotee-blue: #1a004c;
$majorelle-blue: #5549dd;
$purple-x11: #9b44f1;

// SIZES

$footer-desk: 210px;
$footer-mob: 560px;

// Greys

$Grey100Old: #dbdbdb;

$Grey100: #2E2F4A;
$Grey80: #57586E;
$Grey60: #818292;
$Grey40: #ABABB6;
$Grey20: #D5D5DB;
$Grey10: #E9E9EC;
$Grey5: #F4F4F6;


$filter-width: 300px;
$listing-width: calc(100% - 300px);


// Button Colours

$hue-primary:#1A1377;
$hue-primary-hov:#411DA8;
$hue-primary-light: #ebebff;

$hue-secondary: $red;
$hue-secondary-hov: #D2323B;
$hue-secondary-light: #D2323B;

$hue-success: #E8E3EF;
$hue-success-hov: #E8E3EF;
$hue-success-light: #D2323B;
$hue-success-text: #3A3DBB;

$hue-danger: #FF8000;
$hue-danger-hov: #DE5505;
$hue-danger-light: #ffeee8;

$hue-warning: #FCE4D4;
$hue-warning-hov: #FF9900;
$hue-warning-light: #D2323B;
$hue-warning-text: #80420C;

$hue-info: #57586E;
$hue-info-hov: #818292;
$hue-info-light: $Grey5;

$hue-light: $Grey5;
$hue-light-hov: $Grey10;

$hue-dark: #000000;
$hue-dark-hov: #0C0E3B;