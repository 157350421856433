@import "dbd-variables";
@import "mixins";

.btn {

    font-size: 13px;
    font-weight: 600;
    line-height: 1.35rem;
    text-transform: initial;
    letter-spacing: 0.025em;
    padding: 0.5rem 1.35rem;
    white-space: nowrap;
    border-radius: 2rem;

    @include respond-below(sm) {
      font-size: 12px;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: $Grey5;
    }

    &.btn-100 {
        min-width: 100px;
    }

    &.btn-150 {
        min-width: 150px;
    }

    &.btn-200 {
        min-width: 200px;
    }

    &.btn-sm {

        font-size: 0.75rem;
        padding: .35rem 1rem;

        @include respond-below(sm) {
            font-size: 0.75rem;
        }
    }

    &.btn-lg {
        font-size: 0.9rem;
        padding: 0.75rem 1.35rem;
        border-radius: 3rem;
    }
      
    &.btn-primary,
    &.btn-primary:not([href]):not([tabindex]) {
      background-color: $hue-primary;
      border-color: $hue-primary;
    
      &:hover,
      &:focus,
      &:active {
        background-color: $hue-primary-hov;
        border-color: $hue-primary-hov;
        box-shadow: none;
      }
    
    }

    &.btn-outline-primary,
    &.btn-outline-primary:not([href]):not([tabindex]) {
        color: $hue-primary;
        border-color: $hue-primary;

        &:hover,
        &:focus,
        &:active {
            color: #fff;
            background: $hue-primary;
            border-color: $hue-primary;
        }
    }

    &.btn-secondary,
    &.btn-secondary:not(:disabled):not(.disabled).active {
        background-color: $hue-secondary;
        border-color: $hue-secondary;
        color: #fff;

        &:hover,
        &:focus,
        &:active {
            background-color: $hue-secondary-hov;
            border-color: $hue-secondary-hov;
            color: #fff;
        }
    }

    &.btn-outline-secondary,
    &.btn-outline-secondary:not(:disabled):not(.disabled).active {
        border-color: $hue-secondary;
        color: $hue-secondary;

        &:hover,
        &:focus,
        &:active {
            background-color: $hue-secondary;
            border-color: $hue-secondary;
            color: #fff;
        }
    }

    &.btn-success,
    &.btn-success:not(:disabled):not(.disabled).active {
        background-color: $hue-success;
        border-color: $hue-success;
        color: #fff;

        &:hover,
        &:focus,
        &:active {
            background-color: $hue-success-hov;
            border-color: $hue-success-hov;
            color: #fff;
        }
    }

    &.btn-outline-success,
    &.btn-outline-success:not(:disabled):not(.disabled).active {
        border-color: $hue-success;
        color: $hue-success;

        &:hover,
        &:focus,
        &:active {
            background-color: $hue-success;
            border-color: $hue-success;
            color: #fff;
        }
    }

    &.btn-danger,
    &.btn-danger:not(:disabled):not(.disabled).active {
        background-color: $hue-danger;
        border-color: $hue-danger;
        color: #fff;

        &:hover,
        &:focus,
        &:active {
            background-color: $hue-danger-hov;
            border-color: $hue-danger-hov;
            color: #fff;
        }
    }

    &.btn-outline-danger,
    &.btn-outline-danger:not(:disabled):not(.disabled).active {
        border-color: $hue-danger;
        color: $hue-danger;

        &:hover,
        &:focus,
        &:active {
            background-color: $hue-danger;
            border-color: $hue-danger;
            color: #fff;
        }
    }

    &.btn-warning,
    &.btn-warning:not(:disabled):not(.disabled).active {
        background-color: $hue-warning;
        border-color: $hue-warning;
        color: $Grey100;

        &:hover,
        &:focus,
        &:active {
            background-color: $hue-warning-hov;
            border-color: $hue-warning-hov;
            color: $Grey100;
        }
    }

    &.btn-outline-warning,
    &.btn-outline-warning:not(:disabled):not(.disabled).active {
        border-color: $hue-warning;
        color: $Grey100;

        &:hover,
        &:focus,
        &:active {
            background-color: $hue-warning;
            border-color: $hue-warning;
            color: $Grey100;
        }
    }

    &.btn-info,
    &.btn-info:not(:disabled):not(.disabled).active {
        background-color: $hue-info;
        border-color: $hue-info;
        color: #fff;

        &:hover,
        &:focus,
        &:active {
            background-color: $hue-info-hov;
            border-color: $hue-info-hov;
            color: #fff;
        }
    }

    &.btn-outline-info,
    &.btn-outline-info:not(:disabled):not(.disabled).active {
        border-color: $hue-info;
        color: $hue-info;

        &:hover,
        &:focus,
        &:active {
          background-color: $hue-info;
          border-color: $hue-info;
          color: #fff;
        }
    }

    &.btn-light,
    &.btn-light:not(:disabled):not(.disabled).active {
        background-color: $hue-light;
        border-color: $hue-light;
        color: $Grey100;

        &:hover,
        &:focus,
        &:active {
            background-color: $hue-light-hov;
            border-color: $hue-light-hov;
            color: $Grey100;
        }
    }

    &.btn-outline-light,
    &.btn-outline-light:not(:disabled):not(.disabled).active {
        border-color: #fff;
        color: #fff;

        &:hover,
        &:focus,
        &:active {
          background-color: #fff;
          border-color: #fff;
          color: $Grey100;
        }
    }

    &.btn-dark,
    &.btn-dark:not(:disabled):not(.disabled).active {
        background-color: $hue-dark;
        border-color: $hue-dark;
    
        &:not([href]):not([tabindex]) {
          color: #fff;
        }
    
        &:hover,
        &:focus,
        &:active {
          background-color: $hue-dark-hov;
          border-color: $hue-dark-hov;
        }
    }

    &.btn-outline-dark,
    &.btn-outline-dark:not(:disabled):not(.disabled).active {
      border-color: $hue-dark;
      color: $hue-dark;
  
      &:not([href]):not([tabindex]) {
        color: $hue-dark;
      }
  
      &:hover,
      &:focus,
      &:active {
        background-color: $hue-dark;
        border-color: $hue-dark;
        color: #fff !important;
      }
    }

    &.btn-link {
      font-weight: 500;
      padding: 0;
      color: $hue-primary-hov;
      text-decoration: underline;
      text-transform: capitalize;
    //   font-size: 1em;
      letter-spacing: 0;
      background-color: transparent;
      white-space: normal;
      line-height: 1.25em;
    
      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
        background-color: transparent;
        color: $hue-primary;
      }
    
      &:focus {
        box-shadow: none;
      }
    }

      &.btn-feedback {
        z-index: 3;
        position: fixed;
        bottom: 1rem;
        right: 1.5rem;
        width: 10rem;
        background-color: #ff2e43;
        color: white;
        text-transform: none !important;
        white-space: normal !important;

        &:hover,
        &:focus,
        &:active {
            background-color: #5549dd;
            color: white;
          }
      }
    
      .btn-chain {
        font-weight: 600;
        border: 0;
      
        &:hover,
        &:focus,
        &:active {
          background-color: #ddd;
        }
      }
      

      &.my-btn {
        padding: 0.75rem 0.5rem;
        border-radius: 3rem;
      }


    &.switchChain-btn {

        font-size: 13px;
        font-weight: 600;
        color: $Grey100;
        // border-radius: 8px;
        text-transform: inherit;
        box-shadow: none;
        border: none;
        padding: 8px 15px;
        @include flexInlineBox;
        @include flexAlignCenter(center);

        @include respond-below(sm) {
            font-size: 12px;
        }

        img {
            width: 100%;
            max-width: 15px;
            margin-left: 7px;
        }

    }

    &.connectWallet-btn {
        
        // background-color: #fff;
        border-color: $Grey10;
        width: 100%;
        border-radius: 8px ;
        text-align: left;
        padding: 20px;
        text-transform: capitalize;
        text-align: center;
        white-space: normal;
        font-weight: 600;
        cursor: pointer;
        position: relative;

        img.wallet-label {
            width: 100%;
            max-width: 60px;
            margin: 0 auto 10px;

            @include respond-below(sm) {
                max-width: 45px;
            }
        }
        
        label {
            color: $Grey100;
            width: 100%;
            margin-bottom: 0;
            cursor: inherit;
        }

        &:hover {

        }
      }

    &.connectMobile-btn {
        // color: #fff;
        // background-color: $Grey100;
        border-color: $Grey10;
        text-transform: inherit;
        font-weight: 600;
        font-size: 13px;
        padding: 12px 25px;
        // border-radius: 8px;
        @include flexInlineBox;
        @include flexAlignCenter(center);

        @include respond-below(sm) {
            font-size: 11px;
        }


        // &:hover,
        // &:active,
        // &:focus {
        //     background-color: $darkRoyalBlue;
        //     border-color: $darkRoyalBlue;
        // }


        i {
            font-size: 135%;
            margin-right: 10px;
        }
    }

    &.btn-back {
        font-size: 0.8rem;
        font-weight: 600;
        text-transform: initial;
        background-color: transparent;
        padding: 0;

        &:hover,
        &:active,
        &:focus {
            color: $red;
        }
    }

    &.btn-control {

        box-shadow: none;
        text-transform: initial;
        padding: 0.5rem 1.25rem;
        background-color: $Grey5;
        border-radius: 50px;

        @include respond-below(sm) {
            font-size: 12px;
            // padding: 10px 15px; 
        }

        &:hover,
        &:active,
        &:focus {
            background-color: $Grey10;
        }

        i {
            margin-right: 0.75em;
        }
    }

    &.dark {
        background-color: $Grey100;
        border-color: $Grey100;
        color: #fff;

        &:hover,
        &:active,
        &:focus {
            background-color: $Grey80;
            border-color: $Grey80;
        }
    }

    &.outline {
        border-color: $Grey40;

        &:hover,
        &:active,
        &:focus {
            background-color: $Grey80;
            border-color: $Grey80;
            color: #fff;
        }
    }

    &.btn-maxAmount {
        line-height: 1;
        padding: 0.3rem 0.65rem;
        background-color: $hue-primary-light;
        border-color: $hue-primary-light;
        color: $hue-primary;

        &:hover,
        &:active,
        &:focus {
            color: $hue-primary-hov;
        }
    }


    &.filterbuttons {

        border-radius: 0;
        border: 1px solid $Grey10;
        padding: 15px 20px;
        line-height: 1.25em;
        white-space: normal;

        @include respond-below(md) {
            padding: 12px 5px;
            font-size: 11px;
            width: 100%;
        }

        &:hover,
        &:active,
        &:focus {
            box-shadow: none;
            background-color: $Grey5;
            color: $red;
        }
    }

    &.buy-btn {
        font-size: 14px;
        font-weight: 700;
        padding: 0.85rem 1rem;
        border-radius: 3rem;
      }


      &.addToMetamask {

            font-size: 12px;
            font-weight: 500;
            color: $Grey80;
            text-align: left;
            padding: 12px 40px 12px 50px;
            border-radius: 40px;
            background-color: $Grey5;
            position: relative;
            width: 100%;
            max-width: 100%;

            @include respond-below(lg) {
                max-width: 100%;
            }

            @include respond-below(sm) {
                font-size: 11px;
                padding: 10px 40px 10px 45px;
            }


            img {
                width: 100%;
                max-width: 35px;
                height: auto;
                border-radius: 50%;
                background-color: #fff;
                @include verticalAlign(50%);
                display: block;
                left: 5px;

                @include respond-below(sm) {
                    max-width: 30px;
                }
            }

            i {
                @include verticalAlign(50%);
                right: 20px;
            }




    }



}


.filter-tab-wrapper {

    border-bottom: 1px solid $Grey10;
    @include flexBox;

        .btn {
            // align-self: center;
            border-bottom: none;
            border-right: none;

            &:first-of-type {

                border-top-left-radius: 10px;
    
            }
    
            &:last-of-type {
    
                border-top-right-radius: 10px;
                border-right: 1px solid $Grey10;
    
            }
        }

}




.btn-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    @include flexBox;
    @include flexVerticalAlignContent(center);
    @include flexAlignCenter(center);
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.75);

    i {
        font-size: 1.75em;
        color: $Grey80;
    }
}


::ng-deep .dark-mode .filterbuttons {
    color: white;
}

#ALL {
    background-color: $Grey100;
    border-color: $Grey10;
    color: #fff;
}


.btn-share {
    width: 35px;
    height: 35px;
    cursor: pointer;
    border-radius: 8px;
    position: relative;

    i {
        @include centerAlign;
        font-size: 90%;
        color: $Grey60;
        margin-top: 1px;
        margin-left: 1px;
    }
}


label.custom-file-label.btn.btn-info {
  background: #2e4050 !important;
  border-color: #2e4050 !important;
  color: #fff !important;
  padding-left: 20px;
  padding-right: 20px;

  &:hover {
    background: #fff !important;
    border-color: #2e4050 !important;
    color: #2e4050 !important;
  }
}


.dark-mode .btn-link {
  color: white;

  &:hover {
    color: white;
  }
}