@import "dbd-variables";
@import "mixins";


table {

    &.dataTable {

        thead {
            th {
                font-size: 12px;
                padding: 15px 10px;
            }
        }

        tbody {

            tr {

                &:hover {
                    background-color: $Grey5;
                }

                td {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 1.5em;
                    vertical-align: middle;
                    padding: 15px 10px;

                    a, a:not(.btn) {
                        color: $red;
                        text-decoration: none;
                        transition: color 0.25s;
                      
                        &:hover {
                          color: #1a004c;
                          text-decoration: none;
                        }
                      
                          &[target="_blank"] {
                            position: relative;
                            padding-right: 17px;
                      
                            &::after {
                                content: "\f061";
                                font-family: "Font Awesome 5 Free";
                                font-weight: 900;
                                position: absolute;
                                right: 0;
                                @include rotate(-45deg);
                            }
                        }
                      }
                }
            }
        }
    }
}

.dataTables_wrapper {

    .dataTables_length {

        select {
            margin-left: 7px;
            margin-right: 7px;
            padding: 10px 15px;
            cursor: pointer;
            border-color: $Grey20;
        }

    }


    .dataTables_filter {

        input {
            margin-left: 10px;
            outline-offset: 0;
            padding: 10px 15px;
            border-color: $Grey20;
        }

    }

    .dataTables_info {
        font-size: 12px;
        font-weight: 600 !important;
        padding-top: 1em;
    }

    .dataTables_paginate {
        margin-top: 0; 
    }
}

.mat-paginator-outer-container {

    .mat-paginator-container {

        @include respond-below(sm) {
            padding: 0;
        }

        .mat-paginator-page-size {

            @include respond-below(sm) {
                margin-right: auto;
            }
            
        }
        
        .mat-paginator-range-actions {

            .mat-paginator-range-label {

                @include respond-below(sm) {
                    margin: 0 20px;
                }
                
            }
        }
    }


}