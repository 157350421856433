@import "dbd-variables";
@import "mixins";



.badge {

    font-size: 70%;
    height: 20px;

    &.badge-pill {
        padding: 5px 10px;
    }

    &.badge-primary,
    &.badge-primary:hover {

        background-color: $hue-primary-light;
        color: $hue-primary;

    }

    // &.badge-secondary,
    // &.badge-secondary:hover {
        
    //     background-color: ;
    //     color: ;
    // }

    &.badge-success,
    &.badge-success:hover {
        
        background-color: $hue-success;
        color: $hue-success-text;
    }

    &.badge-warning,
    &.badge-warning:hover {
        
        background-color: $hue-warning;
        color: $hue-warning-text;
    }

    // &.badge-danger,
    // &.badge-danger:hover {
        
    //     background-color: $hue-danger-light;
    //     color: $red;
    // }

    // &.badge-warning,
    // &.badge-warning:hover {
        
    //     background-color: ;
    //     color: ;
    // }

    // &.badge-info,
    // &.badge-info:hover {
        
    //     background-color: ;
    //     color: ;
    // }

    // &.badge-light,
    // &.badge-light:hover {
        
    // }
    
    // &.badge-dark,
    // &.badge-dark:hover {
        
    // }
}