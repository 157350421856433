@import "./dbd-variables";
@import "./mixins";
/*


/* RESET STYLES */

html {
  scroll-behavior: smooth;
}

body {
  font-size: 14px;
  line-height: 1.5em;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: $Grey100;
  background: #fff;
  margin: 0;
  min-height: 100vh;
  margin-bottom: 0 !important; 
  @include flexBox;
  @include flexDirection(column);

  app-root {
    min-height: inherit;
    @include flexBox;
    @include flexDirection(column);
  }


  &.light-mode {
    color: $Grey100;
    background: #fff;
  }

  &.dark-mode {
    color: white;
    background: #10052d;
  }
}

body:not(.dark-mode-preloading) {
  transition: all 0.3s linear;
}

.dark-mode .homePageSectionOne.bg-light {
  color: white;
  background: #10052d !important;
}

.dark-mode .icon {
  color: white !important;
}



ol {
  padding-left: 15px;

  li {
    margin-bottom: 10px;
  }
}

::marker {
  font-weight: 700;
}



#btn-login {
  @media (max-width: 991.98px) {
    width: 100%;
  }
}

body.dark-mode {
  // DARK MODE CSS

  .btn-primary,
  a.btn-primary:not([href]):not([tabindex]) {
    background-color: #ff2e43;

    &:hover,
    &:focus,
    &:active {
      background-color: white !important;
      border-color: #1a004c !important;
      color: black !important;
    }
  }

  .btn-outline-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;

    &:hover,
    &:focus,
    &:active {
      background-color: white !important;
      border-color: #343a40 !important;
      color: #1a004c !important;
    }
  }
}



.btn-icon {
  padding: 0;
  color: #1a004c;
  margin-left: 6px;
  margin-right: 6px;

  i {
    font-size: 2.5rem;
    color: #000;

    &:hover {
      opacity: 0.6;
    }
  }
}

// disabled states
.btn-primary:disabled,
.btn-primary.disabled,
.btn-secondary:disabled,
.btn-secondary.disabled,
.btn-info:disabled,
.btn-info.disabled {
  background: darkgrey;
  border-color: darkgrey;
  color: #fff;
}

// .btn-sm {
//   font-size: 0.8rem;
// }

/* HEADINGS */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700 !important;
  font-style: normal;
  word-break: break-word;
  white-space: normal;
}

.h1,
h1 {
  @media (max-width: 767.98px) {
    font-size: 1.65rem;
  }
}

mark {
  padding: 0.2em;
  background-color: #1b004c70;
  margin: 0 0.3rem;
}

.dark-mode mark {
  background-color: #10052d !important;
  color: white !important;
}

.text-info {
  color: #6c757d !important;
}

strong {
  // font-family: 'Space Grotesk';
  // font-weight: bold;
  font-weight: 700;
  font-style: normal;
  // text-transform: uppercase;
}

#btnBack {
  margin-bottom: 20px;
}

/* ==========================================================================
   Containers and Navigation
========================================================================== */


/* ==========================================================================
   Components
========================================================================== */

/* ===== HOMEPAGE ========== */
.btn-banner {
  @media (min-width: 380px) {
    font-size: 20px;
    padding: 0.5rem 5rem !important;
  }
}

.homepage-banner {
  position: relative;
  // top: -15px;
  // margin: 0 -35px 0;

  @media (max-width: 991.98px) {
    margin-left: -15px;
    margin-right: -15px;
  }

  img {
    width: 100%;
  }
}

.homepage-banner-text-area {
  margin: 0 0 0;
  padding: 0 15px;
}

.homepage-signup {
  display: inline-block;
  width: 250px;
}

@media (max-width: 991.98px) {
  .homepage-signup {
    display: inline-block;
    width: 140px;
  }
}

.icons {
  ul {
    display: table;
    margin: 10px auto 0;

    li {
      float: left;
      margin: 0 6px;
    }
  }
}

.dark-mode .cta-block {
  background: #311b77;
}

.cta-block {
  text-align: center;
  padding: 20px 20px 30px;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 17px 5px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 0 17px 5px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 17px 5px rgba(0, 0, 0, 0.12);

  .img-container {
    position: relative;
    margin: -20px -20px 30px;
    overflow: hidden;

    img {
      height: 290px;
      object-fit: contain;
      background-color: #fff;

      @media (max-width: 767.98px) {
        height: 150px;
      }
    }

    .tagline {
      font-size: 8px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 4px;
      color: #fff;
      background-color: $majorelle-blue;
      width: 200px;
      display: inline-block;
      padding: 5px 0;
      position: absolute;
      -moz-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 35px;
      right: -50px;
    }
  }
}

#paypal {
  @media (max-width: 991.98px) {
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

// .bg-extend {
//   position: relative;

//   &:before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 50%;
//     transform: translateX(-50%);
//     width: 100vw;
//     height: 100%;
//     background: inherit;
//   }
// }

/* ===== COLLECTION HEADER ========== */

.collection-image {
  height: 650px;
  position: relative;
  top: -16px;
  margin: 0 -35px 20px;
  background-size: cover !important;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;

  .collection-image-text {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    text-align: center;
    max-width: 800px;
    color: #fff;

    h1 {
      font-size: 4rem;
      font-weight: 50;
      text-transform: uppercase;
      letter-spacing: 0.2rem;
      color: #fff;
    }

    .sub-heading {
      margin-bottom: 0;
      color: $mandarin;
      font-weight: bold;
      font-size: 2.5rem;
    }
  }
}

@media (max-width: 991.98px) {
  .collection-image {
    height: 500px;

    .collection-image-text {
      h1 {
        font-size: 3rem;
        padding: 0 20px;
      }

      .sub-heading {
        padding: 0 20px;
        font-size: 2rem;
      }
    }
  }
}

/* ===== IMAGE GRID ========== */
.imgGrid {
  box-shadow: #999 1px 1px 3px 1px;
  cursor: pointer;
  width: 325px;
  min-height: 310px;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  height: 100%;

  div.imagediv {
    background-color: #1a004c;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }

  img {
    width: 325px;
    height: 325px;
    min-height: 170px;
    display: block;
    object-fit: contain;
    border-radius: 10px 10px 0px 0px;
  }

  @media (max-width: 1350px) {
    width: 300px;

    img {
      width: 300px;
      height: 300px;
    }
  }

  @media (max-width: 1210px) {
    width: 245px;

    img {
      width: 245px;
      height: 245px;
    }
  }

  @media (max-width: 992px) {
    width: 375px;

    img {
      width: 375px;
      height: 375px;
    }
  }

  @media (max-width: 576px) {
    width: auto;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.dark-mode .nft-container {
  border: 1px solid #311b77 !important;
  -webkit-box-shadow: 0 0 17px 5px rgb(131 131 131 / 12%) !important;
  -moz-box-shadow: 0 0 17px 5px rgb(131 131 131 / 12%) !important;
  box-shadow: 0 0 17px 5px rgb(131 131 131 / 12%) !important;

  &:hover {
    -webkit-box-shadow: 0 0 17px 5px rgb(195 186 186 / 20%) !important;
    -moz-box-shadow: 0 0 17px 5px rgb(195 186 186 / 20%) !important;
    box-shadow: 0 0 17px 5px rgb(195 186 186 / 20%) !important;
  }
}

/* ===== SEARCH RESULTS ========== */

.dark-mode .search-item {
  background-color: #311b77;

  &:hover {
    box-shadow: 0 0 2em 0 rgba(255, 255, 255, 15%);
    background-color: #311b77;
    color: white !important;
    opacity: 1 !important;
  }

  h5 {
    color: white;
  }
}

.search-item {
  text-decoration: none !important;
  border-radius: 25px !important;
  height: 200px;

  &:hover {
    color: rgba(0, 0, 0, 0.7) !important;
    background: #fff;
    box-shadow: 0 0 2em 0 rgba(10, 10, 10, 0.1);
  }

  h5 {
    color: #1a004c;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 50px;
  }

  h5 + p {
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 50px;
  }

  img {
    width: 100%;
    object-fit: cover;
    height: 173px;
  }
}

.description-text {
  display: -webkit-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 100px;
}

.description-text-artist-bio {
  display: -webkit-box;
  line-clamp: 8;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 200px;
}

@media (max-width: 991.98px) {
  .search-item {
    height: auto !important;

    img {
      margin-bottom: 15px;
      border-radius: 10px;
      height: unset;
      object-fit: contain;
    }
  }
}

/* ===== TEXT COMPONENTS ========== */


.edition-details-text {
  display: block;
  margin-top: 5px;
  font-size: 65%;
  font-weight: bold;
}



// .badge-info {
//   background-color: #292929;
// }

// .badge-danger {
//   background: rgba(0, 0, 0, 0.5);
// }

.dark-mode .badge-danger {
  background: #dbdbdb;
  color: #1a004c;
}

.alert-secondary {
  background: #bcd3cf;
  color: #fff;
}

/* ===== RESPONSIVE TABLE ========== */

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter {
  text-align: left;
}

.table-responsive {
  padding-bottom: 30px;
  font-size: 0.8rem;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background: #f5f5f5;
  border-color: #f5f5f5;
  color: #2e4050;
  font-weight: bold;
}

.dark-mode .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background-color: #10052d;
  border-color: #311b77;
  color: white !important;
  font-weight: bold;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: #fff;
  border-color: #2e4050;
  color: #2e4050;
}

.dark-mode
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current:hover {
  background-color: #10052d;
  border-color: #311b77;
  color: white !important;
  opacity: 1;
}

table.dataTable {
  color: inherit;

  th:first-child,
  td:first-child {
    border-left: solid 1px;
  }

  th:last-child,
  td:last-child {
    border-right: solid 1px;
  }

  @media (max-width: 620px) {
    td:nth-last-child(2),
    th:nth-last-child(2) {
      border-right: solid 1px;
    }
  }

  @media (max-width: 460px) {
    td:nth-last-child(3),
    th:nth-last-child(3) {
      border-right: solid 1px;
    }
  }

  @media (max-width: 336px) {
    td:nth-last-child(4),
    th:nth-last-child(4) {
      border-right: solid 1px;
    }
  }

  th {
    background-color: #f5f5f5;
  }
}

.dark-mode table.dataTable {
  th {
    background-color: #311b77;
  }
}

table.dataTable,
tr,
th,
td {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.dark-mode table.dataTable,
.dark-mode tr,
.dark-mode th,
.dark-mode td {
  border-color: #311b77 !important;
}

.dark-mode .dataTables_wrapper .dataTables_length select,
.dark-mode .dataTables_wrapper .dataTables_filter input {
  border-color: #311b77 !important;
  background-color: #10052d;
  color: white;

  &:active,
  &:focus {
    border-color: #311b77 !important;
    color: white;
  }
}

.dark-mode .dataTables_info,
.dark-mode .table-hover tbody tr:hover {
  color: white;
}

/* ===== FORMS ========== */

label {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.25em;
}

.form-control,
.ng-select-container {
  //border-radius: 25px !important;
  // background: #f0f1f1 !important;
  color: #909191;
}

.form-control:not([readonly]):focus,
.ng-select-container:not([readonly]):focus {
  color: #495057 !important;
  border-color: #495057 !important;
  background: #fff !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.search-form {
  min-width: auto;
}

.hide-arrow .ng-arrow-wrapper {
  display: none;
}

/* ===== MODAL CONTENT ========== */

// BOOSTRAP MODAL

.dark-mode .modal-content {
  background-color: #1a004c;
}

.dark-mode .modal-header .close,
.dark-mode button.close {
  color: white;
}

ngb-modal-window.d-block.modal.fade.show {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  overflow-y: scroll;
  z-index: 10001;
}

ngb-modal-backdrop {
  &.modal-backdrop.fade.show {
    z-index: 10001 !important;
  }
}



.title-row {
  @media (max-width: 991.98px) {
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  @media (max-width: 419.98px) {
    div[role="group"] {
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    button {
      width: 100%;
    }
  }
}

mat-expansion-panel {
  background-color: rgb(240, 241, 241);
}

mat-panel-title {
  color: rgb(26, 0, 76);
}

.mat-sort-header-container {
  justify-content: center;
}

.mat-horizontal-stepper-header {
  flex-direction: row !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  align-content: center !important;
}

.mat-tab-header {
  z-index: 0;
}



.dark-mode .mat-paginator-container {
  background-color: #10052d;
  color: white;

  .mat-select-arrow,
  .mat-select-value-text {
    color: white;
  }

  .mat-icon-button.mat-button-disabled.mat-button-disabled {
    color: #424242;
  }
}

.mat-tab-label.mat-tab-label-active,
.dark-mode .mat-tab-label.mat-tab-label-active {
  opacity: 1;
}

.dark-mode .mat-tab-label {
  color: white;
}

.dark-mode .mat-tab-header {
  border-color: #311b77;
}

i.fas.fa-external-link-alt.fa-xs {
  margin-left: 3px;
  transform: translateY(-20%);
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 0px !important;
  bottom: 50px !important;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}

#recaptchaV2css div div div div * {
  @media (max-width: 480px) {
    transform: scale(0.75);
    -webkit-transform: scale(0.75);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}


.chain-normal-logo {
  display: inline-block !important;
}

.chain-dark-mode-logo {
  display: none !important;
}

.dark-mode {
  .chain-normal-logo {
    display: none !important;
  }

  .chain-dark-mode-logo {
    display: inline-block !important;
  }
}

.ng-dropdown-panel {
  z-index: 1 !important;
}

.authentication-modal {
  max-height: 95vh;
  overflow-y: auto;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.rounded-1 {
  border-radius: 1rem;
}

.regular-font {
  font-size: 1rem;
}

// Hover over
/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  z-index: 0;
}

// .tooltip-medium-screen {
//   padding: 0 0;
//   @media (max-width: 964px) {
//     padding-left: 2rem;
//   }

//   @media (max-width: 417px) {
//     padding-left: 2rem;
//   }
// }

.tooltip-medium-screen .tooltip .tooltip-text {
  padding: 0 0;

  @media (max-width: 964px) {
    left: 500%;
  }

  @media (max-width: 417px) {
    left: 500%;
  }
}

/* Tooltip text */
.tooltip .tooltip-text {
  visibility: hidden;
  max-width: 20rem;
  width: 240px;
  background-color: #f5f5f5;
  color: #1a004c;
  text-align: center;
  padding: 5px 0;
  border-width: 1px;
  border-style: solid;
  border-color: #1a004c;
  border-radius: 15px;
  padding: 16px !important;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -50px;
}

/* Tooltip arrow */
.tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #1a004c transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltip-text {
  visibility: visible;
}

// this is for confetti
.confetti canvas {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  /* we need these for full-screen effect */
  width: 100%;
}

.btn-share-icon {
  font-size: 1.5rem;
  margin-right: 1rem;
  cursor: pointer;
}

.btn.btn-token {
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  text-transform: unset !important;
  font-weight: 400;
  font-size: 12px;
  color: #1a004c;
  padding: 15px 10px;

  strong {
    font-weight: 700;
  }

  &.btn-active-token {
    border-color: #a67aff;

    strong {
      color: #722cff;
    }
  }

  &:focus,
  &:active,
  &:hover {
    box-shadow: none;
  }

  p {
    line-height: 1.25em;
    margin-bottom: 0;
  }
}

.btn-back-step {
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid $Grey100Old;
  color: #1a004c;
}

.btn-next-step {
  border-radius: 8px;
  background-color: #ff2e43;
  color: white;
  white-space: "break-spaces";
}

.btn-mint {
  font-size: 14px;
  margin-bottom: 1rem;
  width: 220px;
  height: 40px;
}

.btn-burn {
  background-color: #ff2e43 !important;
  border: none;
}

.btn-burn:hover {
  background-color: #5f2dff !important;
}

.btn-inside-max {
  position: absolute;
  right: 155px;
  top: 2px;
  bottom: 4px;
  line-height: 1 !important;
  font-weight: 600;
}

.img-chain {
  object-fit: contain;
  width: 75%;
}

.img-light {
  display: inline-block;
}

.img-dark {
  display: none;
}

.chain-container {
  width: 230px;
  border: 1px solid;
  border-radius: 0.8rem;
  display: flex;
  justify-content: center;

  @media (max-width: 557px) {
    width: 75%;
  }
}

.chain-container:active {
  border-color: #1a004c;
}

.chain-container:hover {
  background-color: #f8f9fa;
}

.price-container {
  width: 100%;
  background-color: #f1f1f1;
  border-radius: 10px;
  color: #1a004c;
}

.price-text {
  font-size: 18px;
}

.mint-price-text {
  font-size: 24px;
}

.mint-nfts-container {
  padding-left: 15px;
  padding-right: 15px;
}

// .btn-mint-nfts {
//   font-size: 1rem;
//   margin-right: 16px;
// }

// .btn-discount {
//   font-size: 1rem;
//   text-transform: capitalize;
//   border: 1px solid #ff2e43;
//   color: #ff2e43;
//   padding: 0.3rem 2rem;

//   &:focus {
//     box-shadow: none;
//   }
// }

.btn-remove {
  background-color: #f1f1f1;
  border-radius: 8px;
  text-transform: capitalize !important;
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px;
}

.btn-direct {
  width: 250px;
  color: #5549dd;
  border: 1px solid #5549dd;
  border-radius: 10px;
  text-transform: capitalize;

  &:hover {
    color: white;
    background-color: #5549dd;
  }

  @media (max-width: 557px) {
    width: 100%;
  }
}

.btn-profile-link {
  font-size: large;
  text-decoration: none;

  &:hover {
    background-color: inherit !important;
  }
}

/* Style buttons */
.btn-attribute {
  /* Blue background */
  background-color: rgb(219, 219, 219);
  /* Remove borders */
  border: none;
  color: rgb(26, 0, 76);
  /* Some padding */
  padding: 10px 14px;
  margin: 10px;
  /* Set a font size */
  font-size: 10px;
  /* Mouse pointer on hover */
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}
.btn-attribute:hover {
  opacity: 0.65;
  -moz-transition: opacity 0.3s ease-in-out;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.btn-attribute:active {
  background-color: rgb(219, 219, 219);
  color: rgb(26, 0, 76);
}

.agree-container {
  color: #1a004c;
  width: 100%;
  background-color: #f1f1f1;
  padding: 1rem 0;
  border-radius: 10px;
}

.details-font {
  font-size: 1rem;
  font-weight: 500;
}

.total-price-font {
  font-size: 1.5rem;
  font-weight: 600;
}

::ng-deep .dark-mode {
  .chain-container {
    color: white;
    background-color: #311b77 !important;
  }

  .chain-container:active {
    background-color: inherit;
  }

  .chain-container:hover {
    opacity: 0.5;
  }

  .btn-remove {
    color: white;
    background-color: #1a004c;
  }

  .btn-direct {
    color: #fff;
    border: 1px solid #fff;
  }

  .agree-container {
    color: white;
    background-color: #1a004c;
  }
}

.open-fullscreen {
  margin-top: 10px;

  @media (max-width: 425px) {
    float: unset !important;
    display: block;
    margin-bottom: 10px;
  }
}

.share-container {
  width: auto;
  border-radius: 10px;
  padding: 0.5rem 1.1rem 0.5rem 1.4rem;
  background-color: #3e00ac;
  color: white;
  font-size: 24px;
  font-weight: 500;
}



.invest-info-container {
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  border-radius: 15px;
}

.border-dashed {
  border-top: 1px dashed #dee2e6;
  border-bottom: 1px dashed #dee2e6;
}



.success-mint-container {
  margin: auto;
  width: 540px;

  @media (max-width: 576px) {
    width: auto;
  }
}

.modal-expanded-md {
  @media (min-width: 992px) {
    width: 550px !important;
  }
}

input[type="file"].customInputFile {
  color: rgba(0, 0, 0, 0);
  width: 100px;
}
