@import "./dbd-variables";
@import "./mixins";




p {
  &.process {
    @include respond-below(sm) {
      font-size: 11px;
    }
  }
}


#buynowbutton a {
  padding-right: 50px !important;
  padding-left: 50px !important;
  background-color: #FFBD00 !important;
  border-color: #FFBD00 !important;
  color: #1a004c;
  width: 100%;
}

#buynowbutton a:hover {
  background-color: #ffcf47 !important;
  border-color: #ffcf47 !important;
}

#getinsurancebutton a {
  width: 100%;
}

// .collapse-label {
//   margin-top: 15px;
//   margin-bottom: 0px !important;
// }

.direct-btn {
  width: 100px;
  height: 18px;
  background-color: #f1f1f1;
  border-radius: 1rem;
  box-shadow: 3px 5px #f8f9fa;
}

img.chain-dark-mode-logo, img.chain-normal-logo {
  height: unset !important; 
  min-height: unset !important; 
  border-radius: unset !important
}



/* Customize the label (the container) */
.label-wrapper {
  font-size: 13px;
  font-weight: 500;
  width: 100%;
  display: block;
  position: relative;
  color: $Grey60;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @include flexBox;
  @include flexAlignCenter(center);

    /* On mouse-over, add a grey background color */
    &:hover,
    &:active,
    &:focus {

      color: $Grey100;

      input ~ .checkmark {
        // border-color: $Grey100;
      }

    }

    img.label-icon {
      align-self: center;
      max-width: 20px;
      margin-right: 10px;
    }

    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      /* When the checkbox is checked, add a blue background */
      &:checked ~ .checkmark,
      &:checked ~ .radiomark {
        background-color: $hue-primary;
        border-color: $hue-primary;

          /* Show the checkmark when checked */
          &:after {
            display: block;
          }
      }

      &:checked ~ .text-label {
        background-color: $hue-primary;
        border-color: $hue-primary;
        color: #fff;
      }


    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
      background-color: transparent;
      border: 1px solid $Grey40;
      border-radius: 4px;

      /* Style the checkmark/indicator */
      &:after {
        top: 2px;
        left: 6px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        content: "";
        position: absolute;
        display: none;
      }

    }

    /* Create a custom radiobox */
    .radiomark {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: transparent;
        border: 2px solid $Grey10;
        border-radius: 50%;
  
        /* Style the checkmark/indicator */
        &:after {
          top: 50%;
          left: 4px;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          width: 6px;
          height: 6px;
          border-radius: 50%;
          content: "";
          position: absolute;
          display: none;
          background-color: #fff;
        }
  
      } 

      .text-label {
        font-weight: 600;
        padding: 10px 20px;
        background-color: #fff;
        border: 1px solid $Grey20;
        border-radius: 40px;
        display: inline-block;

        @include respond-below(sm) {
          font-size: 12px;
          padding: 10px 15px; 
      }
      }

      &.right {

        padding-left: 0;
        padding-right: 30px;

        .checkmark {
          left: auto;
          right: 0;
        }

        .radiomark {
          left: auto;
          right: 0;
        }
      }

}

.sort-label {

  &:first-of-type {

    .text-label {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &+ .sort-label {

      .text-label {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

}



.top-row {
  position: sticky;
  top: 102px;
  backdrop-filter: blur(15px);
  background: rgba(255, 255, 255, 0.9);
  z-index: 50;

  @include respond-below(xl) {
    top: 91px;
  }

  @media (max-width: 389px) {
    top: 88px;
  }

}


.filter-col {

  max-width: $filter-width;
  @include flex(0 0 $filter-width);

  #filterSection {
    border: 1px solid #E8E9EB;
    border-radius: 10px;
    padding: 20px;
    height: 100%;
    @include calc(max-height, #{"100vh - 200px"});
    position: sticky;
    top: 172px;
    overflow-y: auto;

    @include respond-below(xl) {
      border: none;
      border-radius: 0;
      @include calc(max-height, #{"100vh - 60px"});
    }
  }

  &.show {
    display: block;
  }

  &.collapse:not(.show) {

    display: none;

    &+ .listing-col {
      max-width: 100%;
      @include flex(0 0 100%);
    }

  }

  &.collapsing {

    @include respond-above(xl) {
      width: 0;
      max-width: 0;
      @include flex(0);
      @include transition(all .15s ease);

      #filterSection {
        display: none;
      }
    }

  }


  @include respond-below(xl) {

    width: 100%;
    max-width: 350px;
    height: 100vh;
    @include transition(all 0.2s ease);
    display: none;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 999999;

    @include respond-below(xs) {
      @include calc(max-width, #{"100vw - 80px"});
    }

  &:before {
    content: '';
    display: none;
    opacity: 0;
    @include transition(all 0.2s ease);
  }

  &.collapsing {
    margin-right: -50%;
    display: block;
    height: 100%;

    &:before {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      display: block;
      opacity: 1;
      background-color: rgba(0,0,0,0.4);
      z-index: -1;
      @include transition(all 0.2s ease);
    }

    #filterSection {
      opacity: 0;
    }

  }

  &.show {
    display: block;
    left: 0;

    &:before {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      display: block;
      opacity: 1;
      background-color: rgba(0,0,0,0.8);
      z-index: -1;
      @include transition(all 0.2s ease);

    }

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      background-color: #fff;
      z-index: -1;
      @include transition(all 0.2s ease);
    }
  }

  }

}


.filter-toggle-wrapper {

  z-index: 9999999;


  .navbar-toggler {

    z-index: 999999;
    display: none;
    transition: all ease 0.2s;

    .icon-bar {
      background-color: #fff;
    }

    &[aria-expanded="true"] {

      display: block;
      transition: all ease 0.2s;

      .icon-bar {
        background-color: #fff;
      }
    }

  }

  .navbar-toggler {
    border: none;
    background: transparent !important;
    position: absolute;
    right: 10px;
    z-index: 99999;

    &[aria-expanded="true"] {
      position: fixed;
      right: 10px;
      top: 20px;
    }

    &:hover,
    &:active,
    &:focus {
      background: transparent !important;
    }

    .icon-bar {
      height: 2px;
      width: 25px;
      display: block;
      background-color: #fff;
      transition: all 0.2s;
      border-radius: 1px;

      &+.icon-bar {
        margin-top: 5px;
      }
    }

    .top-bar {
      @include transformValue(rotate(45deg));
      transform-origin: 10% 10%;
    }

    .middle-bar {
      opacity: 0;
    }

    .bottom-bar {
      @include transformValue(rotate(-45deg));
      transform-origin: 10% 90%;
    }

    &[aria-expanded="false"] {

      .top-bar {
        transform: rotate(0);
      }

      .middle-bar {
        opacity: 1;
      }

      .bottom-bar {
        @include transformValue(rotate(0));
      }

    }

  }
}

.listing-col {
  max-width: $listing-width;
  @include flex(0 0 $listing-width);
  min-height: 250px;
  
  @include respond-below(xl) {
    max-width: 100%;
    @include flex(0 0 100%);
  }
}


.img-loader {
  width: 100%;
  height: 0;
  padding-top: 100%;
  background-color: $Grey40;
  animation: imgLoader 1s linear 0s infinite normal none running;
}

@keyframes imgLoader {
  0%  {background-color: $Grey5;}
  25%  {background-color: $Grey10;}
  50%  {background-color: $Grey20;}
  75%  {background-color: $Grey10;}
  100%  {background-color: $Grey5;}
}


// NFT Card (Listing view) 

.nft-container {
  width: 100%;
  height: 100%;
  padding: 8px;
  border-radius: 10px;
  @include boxShadow(0 0 2px 1px #E8E9EB);
  transition: all ease 0.2s;
  position: relative;
  letter-spacing: 0.025em;

  @include respond-below(sm) {
    padding: 0;
    border-radius: 6px;
  }

  &:hover {

    @include respond-above(sm) {

      transform: translateY(-6px);
      @include boxShadow(0 4px 10px 2px #E8E9EB);
      transition: all ease 0.2s;

    }

  }

  img.main {
    width: 100%;
    display: block;
  }


  &.details {

    padding: 0;
    box-shadow: none;
    width: 100%;
    max-width: 690px;
    margin: auto;

    &:hover {
      transform: none;
      box-shadow: none;
    }

    .nft-att {

      .asset-cat,
      .event-cat {
        padding-left: 40px;
      }

      h6 {
        font-size: 12px;
        margin-bottom: 5px;

        &:before {
          left: -35px;
          font-size: 120%;
          top: -3px;
          width: 30px;
          text-align: center;
        }
      }
  
      p {
        font-size: 12px;
      }

    }

  }

  a.nft-link {
    cursor: pointer;
    display: block;
    text-decoration: none;
    color: initial;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .thumbnail {
    background-color: #000;
    border-radius: 7px 7px 0 0;
    position: relative;

    // @include respond-below(sm) {
    //   padding-top: 25px;
    // }

    img {

      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      border-radius: 10px 10px 0px 0px;

      &:not([hidden]) + .network-wrapper {
        display: block;
      }
    }

    .network-wrapper {
      width: 35px;
      height: 35px;
      background-color: #000;
      border-radius: 50%;
      overflow: hidden;
      position: absolute;
      top: 15px;
      left: 15px;
      display: none;

      @include respond-below(sm) {
        width: 25px;
        height: 25px;
        top: 10px;
        left: 10px;
      }

      img {
        display: block;
      }
    }

  }

  .nft-att {

    background: #000;
    line-height: 1.25em;
    min-height: 120px;

    @include respond-below(xl) {
      min-height: 105px;
    }

    @include respond-below(lg) {
      min-height: 120px;
    }

    @include respond-below(md) {
      min-height: 125px;
    }

    p {
      font-size: 10px;
      color: #dcdcdc;
      margin-bottom: 0;

      @include respond-below(sm) {
        font-size: 9px;
        line-height: 1.75em;
      }

      @media (max-width: 389px) {
        font-size: 8px;
      }
    
      
      span {
        
        display: inline-block;
        padding-right: 7px;

        &:last-of-type {
          padding-right: 0;
        }
      }
    }

    h6 {
      font-size: 10px;
      color: #fff;
      margin-bottom: 2px;
      position: relative;

      @include respond-below(sm) {
        font-size: 9px;
      }

      &:before {
        font-family: "Font Awesome 5 Free";
        position: absolute;
        top: -1px;
        left: -18px;
      }
    }

    .asset-cat {

      padding-left: 30px;
      padding-right: 15px;

      h6 {
        &:before {
          content: "\f3ed";
        }
      }
    }

    .event-cat {

      padding-left: 30px;
      padding-right: 10px;

      h6 {
        &:before {
          content: "\f6f1";
        }
      }
    }
  }

  .nft-details {

    color: $Grey100;
    padding: 10px;

    @include respond-below(sm) {
      padding: 10px 12px 12px;
    }

    .title-wrapper {
      position: relative;

      .text-danger {
        font-size: 9px;
        font-weight: 600;
        line-height: 1;
        margin: 0;
        position: absolute;
        top: 2px;
        left: 0;

        @include respond-below(sm) {
          font-size: 9px;
          top: 0;
        }
      }
      
      .policy-title {
        font-size: 14px;
        padding-top: 17px;
        margin-bottom: 5px;
        letter-spacing: 0;

        @include respond-below(sm) {
          font-size: 13px;
          padding-top: 15px;
        }
      }
    }

    label {
      font-size: 11px;
      font-weight: 500;
      line-height: 1.1em;
      // color: $Grey60;
      margin-bottom: 8px;

      @include respond-below(sm) {
        font-size: 9px;
      }

      & + p {
        font-size: 13px;
        font-weight: 600;
        line-height: 1.1em;
        margin-bottom: 0;

        @include respond-below(sm) {
          font-size: 11px;
          font-weight: 700;
        }
      }
    }

  }


  &.my-nft,
  &.my-covers {

    .thumbnail {

      // @include respond-below(sm) {
      //   padding-bottom: 25px;
      // }

      img {

        & + .network-wrapper {
          display: block;
        }
      }

      .badge {
        position: absolute;
        top: 0px;
        right: 0px;
        border-radius: 0 5px 0 10px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    } 

    .nft-details {

      label {
        font-size: 12px;

        @include respond-below(sm) {
          font-size: 10px;
        }
      }
    }


  }

  @include respond-below(sm) {

    .btn {
      font-size: 10px;
      font-weight: 700;
      padding: 0.5rem 1rem;
    }
  }


}



.row.nft-listing {

  min-height: 175px;

  > .col,
  > [class*="col-"] {

    @media (min-width: 1920px) {
      @include flex(0 0 20%);
      max-width: 20%;
    }

    @media (max-width: 1919px) and (min-width: 1670px) {
      @include flex(0 0 25%);
      max-width: 25%;
    }

    @media (max-width: 1669px) and (min-width: 1350px) {
      @include flex(0 0 33.3333%);
      max-width: 33.3333%;
    }

    @media (max-width: 1349px) and (min-width: 1200px) {
      @include flex(0 0 50%);
      max-width: 50%;
    }
    
  }

}

.nft-wrapper {

  border-radius: 10px;
  overflow: hidden;
  @include flexBox;

  .nft-container {
    padding: 0;
    box-shadow: none;
    align-self: center;
  }
}


.nft-details-wrapper {

  width: 100%;
  max-width: 480px;

  @include respond-below(lg) {
    max-width: 100%;
  }

  h3 {
    font-size: 1.65rem;
  }

  a, a:not(.btn) {
    color: $red;
    text-decoration: none;
    transition: color 0.25s;
  
    &:hover {
      color: #1a004c;
      text-decoration: none;
    }
  
      &[target="_blank"] {
        position: relative;
        padding-right: 17px;
  
        &::after {
            content: "\f061";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            position: absolute;
            right: 0;
            @include rotate(-45deg);
        }
    }
  }
  

}

