@import "dbd-variables";
@import "mixins";





.border {
  border: 1px solid $Grey10 !important;
}


label {
  color: $Grey80;
}


.form-control {

  @include calc(height, #{"2rem + 1rem + 2px"});
  font-size: 0.8rem;
  font-weight: 500;
  border-color: $Grey20;
  border-radius: 8px;

  
  &[readonly] {
    background-color: $Grey5;
    color: $Grey80;

    &:focus,
    &:active {
      box-shadow: none;
      background-color: $Grey10;
      border-color: $Grey20;
      outline: none;
    }
  }

  &.cover-input {
    height: 2.75em !important;
    padding-right: 40px;

    &+ .form-label {
      @include verticalAlign(50%);
      right: 15px;
      line-height: 1;
    }
  }
}

ng-select {

  &.form-control {

    height: auto !important;
    padding: 0;
    border: none;
    width: 100%;
    max-width: 125px;
    z-index: 10;

    .ng-select-container {
      @include calc(height, #{"2rem + 1rem + 2px"});
      border-radius: 8px 0 0 8px;
      cursor: pointer;

      .ng-value-container {
        padding-left: .75rem;
      }

      .ng-arrow-wrapper {
        width: 35px;
      }
    }

    .ng-dropdown-panel {

      .ng-dropdown-panel-items {

        .ng-option {

          &:hover {
            background-color: $Grey5;
          }
  
          &.ng-option-selected,
          &.ng-option-marked {

            background-color: $Grey10;

            &:hover {
              background-color: $Grey5;
            }
          }
        }
      } 
    }

    &.price {

      .ng-select-container {
        color: #fff;
        border-color: $hue-primary;
        background-color: $hue-primary;

        &:hover,
        &:focus,
        &:active {
          border-color: $hue-primary-hov;
          background-color: $hue-primary-hov;
        }

        .ng-arrow-wrapper {

          .ng-arrow {
            border-color: #fff transparent transparent;
          }
          
        }
      }

      &.ng-select-opened {

        .ng-arrow-wrapper {

          .ng-arrow {
            border-color: transparent transparent #fff;
          }
          
        }
      }
    }
  }
}

.max-group {

  position: relative;

  .form-control {
    padding-right: 70px;

    &.is-invalid {
      padding-right: 95px !important;

      & + .btn {
        right: 35px;
      }
    }
  }

  .btn-maxAmount {

    @include verticalAlign(50%);
    right: 15px;
  }
}

.input-group {


  & > ng-select {

    &.form-control {  

      .ng-select-container {
        border-radius: 8px 0 0 8px;
      }
    }
  }


  .input-group-prepend {

    .input-group-text {
      font-size: 0.75rem;
      font-weight: 500;
      border-color: $Grey20;
      border-radius: 8px;
      background-color: $Grey20;
      color: $Grey80;
    }

  }

  & > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }


  &.claim-rewards {

    input.form-control {
      font-size: 14px;
      letter-spacing: 0.025em;
      font-weight: 600;
      height: 45px;
      padding: 10px 20px;
      padding-right: 5px;
      border-radius: 3rem 0 0 3rem;
      color: #fff;
      background-color: $red;
      border: 1px solid $red;
      border-right: 1px solid #fff;

      &[readonly],
      &[disabled] {
        color: #fff;
        background-color: $red;
        border: 1px solid $red;
        border-right: 1px solid #fff;
      }
    }

    .btn {
      border-radius: 0 3rem 3rem 0;
      padding: 10px 20px;
      line-height: 1.75em;
      // height: 45px;
    }

    
  }

    &.qr-form {

        max-width: 220px;
        margin: 0 auto;
        position: relative;

        .form-control[readonly] {
            border-radius: 40px;
            padding: 20px 25px;
            padding-right: 50px;
            font-size: 14px;
            border: 1px solid $Grey20;
            background-color: #fff !important;
            letter-spacing: 1px;
        }

        .btn {
            padding: 5px 20px;
            height: 100%;
            text-align: right;
            border: none;
            box-shadow: none;
            color: inherit;
            z-index: 999;
            background-color: transparent;
            // @include flexBox;
            // @include flexVerticalAlignContent(center);
            // @include flexAlignCenter(center);
            @include verticalAlign(50%);
            right: 0;

            i {
                font-size: 120%;
                margin-top: 2px;
            }

            &:hover,
            &:active,
            &:focus {
                color: inherit;
            }
        }
    }
}

// QR Code

.qrcode {

    canvas {
      // border: 1px solid $Grey10;
      @include boxShadow(0 0 10px 2px rgba(0,0,0,0.1));
      border-radius: 8px;
      padding: 20px;
    }
  
}


.card-qrcode {
    border-radius: 50px;
    background-color: #ececec;
    height: 350px;
    min-width: 150px;
  
    @media (max-width: 700px) {
      width: 100% !important;
    }
  
    .card-body {
      border-radius: 50px;
    }
  }
  
  ::ng-deep .dark-mode .card-qrcode {
    background-color: #1a004c;
  }
  

  .qr-container {

    max-width: 200px;
    position: relative;

    .qrcode-expired {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include flexVerticalAlignContent(center);
        @include flexAlignCenter(center);
        @include flexDirection(column);
        display: none;
        background-color: rgba(255,255,255,0.85);
    
        i {
            font-size: 36px;
            color: #ff8100;
            margin-bottom: 15px;
        }

        .btn {
            text-transform: inherit;
            font-weight: 600;
            color: #fff;
            background-color: $Grey100;
            padding: 5px 20px;

            &:hover,
            &:active,
            &:focus {
                background-color: $darkRoyalBlue;
                border-color: $darkRoyalBlue;
            }
        }
      
    
      }
      
      .qrcode-blur {
        filter: blur(7px);
        
        canvas {
          box-shadow: none;
        }
      }
    
  }
  
  

  .loading-wrapper {
    position: absolute;
    height: 100%;
    text-align: center;
    top: -2px;
    padding-top: 30px;
    left: 0;
    width: 100%;
    z-index: 999;
    background: rgba(255, 255, 255, 0.98);

    div {
      display: inline-block;
      padding: 20px;

      h4 {
        @include respond-below(sm) {
          font-size: 1.25rem;
        }
      }

      p {
        @include respond-below(sm) {
          font-size: 0.85rem;
        } 
      }
    }
  }



  .btn-group {

    .btn {

      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &:first-of-type {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:last-of-type {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-right-color: inherit;
      }
    }

    &.assets-cat {

      @include respond-below(sm) {
        @include flexWrap(wrap);
      }

      .btn {

        color: $hue-primary;
        background-color: $Grey5;
        border-color: $Grey5;
        width: 100%;
        padding: 0.65rem 0.5rem;
        cursor: pointer;
        border-right-color: #fff;
        margin: 0;
        // @include calc(height, #{"2rem + 1rem + 2px"});
        // @include flexInlineBox;
        // @include flexAlignCenter(center);
        // @include flexVerticalAlignContent(center);

        @include respond-below(md) {
          font-size: 11px;
        }

        @include respond-below(sm) {
          width: 33.3333%;

          &:nth-child(-n+3) {
            margin-bottom: 1px;
          }

          &:nth-child(3n+3) {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-right-color: $Grey5;
    
            &.selected {
              border-right-color: $hue-primary;
            }
          }

          &:nth-child(3n+1) {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;

          }
        }

        &:last-of-type {
          border-right-color: $Grey5;

          &.selected {
            border-right-color: $hue-primary;
          }

        }

        &:hover,
        &:focus,
        &:active {
          background-color: $hue-primary-hov;
          border-color: $hue-primary-hov;
          box-shadow: none;
          color: #fff;

        }

        &.selected {
          border-color: $hue-primary;
          background-color: $hue-primary;
          color: #fff;

        }

      }
    }


    &.switch-checkbox {

      .btn {

        color: $hue-primary;
        border-color: $hue-primary;
        background-color: transparent;
        min-width: 85px;
        @include calc(height, #{"2rem + 1rem + 2px"});
        @include flexInlineBox;
        @include flexAlignCenter(center);
        @include flexVerticalAlignContent(center);

        &:hover,
        &:focus,
        &:active {
          background-color: $hue-primary-hov;
          border-color: $hue-primary-hov;
          box-shadow: none;

          label {
            color: #fff;
          }
        }

        label {
          cursor: pointer;
        }

        &.selected {
          border-color: $hue-primary;
          background-color: $hue-primary;

          label {
            color: #fff;
          }
        }

        &.disabled {

          border-color: $Grey5;
          background-color: $Grey5;
          cursor: default;

          label {
            color: $Grey100;
          }

          &:hover,
          &:focus,
          &:active {
            border-color: $Grey5;
            background-color: $Grey5;
            box-shadow: none;

            label {
              color: $Grey100;
            }

          }

        }
      }
    }
  }


  .asset-wrapper {

    border-radius: 4px;

  }